export const DownloadIcon = ({color}) => (
    <svg width="19" height="17" viewBox="0 0 19 17" fill="none">
        <path d="M19 14.6818V11.5909C19 11.386 18.9166 11.1894 18.7681 11.0445C18.6197 10.8996 18.4183 10.8182 18.2083 10.8182C17.9984 10.8182 17.797 10.8996 17.6485 11.0445C17.5001 11.1894 17.4167 11.386 17.4167 11.5909V14.6818C17.4167 14.8868 17.3333 15.0833 17.1848 15.2282C17.0363 15.3731 16.835 15.4545 16.625 15.4545H2.375C2.16504 15.4545 1.96367 15.3731 1.81521 15.2282C1.66674 15.0833 1.58333 14.8868 1.58333 14.6818V11.5909C1.58333 11.386 1.49993 11.1894 1.35146 11.0445C1.20299 10.8996 1.00163 10.8182 0.791667 10.8182C0.581704 10.8182 0.38034 10.8996 0.231874 11.0445C0.0834077 11.1894 0 11.386 0 11.5909V14.6818C0 15.2966 0.250223 15.8863 0.695621 16.321C1.14102 16.7558 1.74511 17 2.375 17H16.625C17.2549 17 17.859 16.7558 18.3044 16.321C18.7498 15.8863 19 15.2966 19 14.6818ZM13.9492 10.6482L9.99083 13.7391C9.85107 13.8469 9.67814 13.9055 9.5 13.9055C9.32186 13.9055 9.14893 13.8469 9.00917 13.7391L5.05083 10.6482C4.9066 10.5153 4.81873 10.3344 4.8046 10.1413C4.79047 9.94822 4.85111 9.75698 4.97452 9.60543C5.09793 9.45388 5.27513 9.35307 5.47104 9.32294C5.66696 9.29281 5.86733 9.33556 6.0325 9.44273L8.70833 11.5291V0.772727C8.70833 0.567787 8.79174 0.371241 8.94021 0.226327C9.08867 0.0814123 9.29004 0 9.5 0C9.70996 0 9.91133 0.0814123 10.0598 0.226327C10.2083 0.371241 10.2917 0.567787 10.2917 0.772727V11.5291L12.9675 9.44273C13.0472 9.36928 13.1417 9.31279 13.245 9.27684C13.3482 9.24089 13.458 9.22627 13.5673 9.23389C13.6767 9.24152 13.7832 9.27122 13.8802 9.32113C13.9771 9.37104 14.0624 9.44007 14.1307 9.52383C14.1989 9.60759 14.2485 9.70427 14.2765 9.80774C14.3044 9.91121 14.31 10.0192 14.2929 10.1249C14.2759 10.2306 14.2365 10.3317 14.1773 10.4218C14.1182 10.5119 14.0405 10.5889 13.9492 10.6482Z" fill={color}/>
    </svg>
)

export const LikeIcon = ({color}) => (
    <svg width="18" height="17" viewBox="0 0 18 17" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M12.7969 0C12.085 0.000893692 11.3808 0.159213 10.7278 0.465192C10.0748 0.771172 9.48676 1.21832 9 1.77907C8.28667 0.957268 7.36095 0.385923 6.34301 0.139205C5.32508 -0.107514 4.26194 -0.0182115 3.29161 0.395519C2.32128 0.809249 1.48858 1.5283 0.901615 2.45932C0.314645 3.39034 0.000513647 4.49034 0 5.61651C0 11.2087 8.35594 16.6977 8.71875 16.9133C8.80624 16.97 8.90637 17 9.00844 17C9.1105 17 9.21064 16.97 9.29813 16.9133C9.64406 16.6977 18 11.2087 18 5.61651C17.9985 4.12741 17.4498 2.69976 16.4744 1.64681C15.4989 0.593861 14.1764 0.00160723 12.7969 0ZM9 15.6777C7.54594 14.6758 1.125 9.99435 1.125 5.61651C1.12572 4.68142 1.40227 3.77087 1.91457 3.01682C2.42686 2.26278 3.14829 1.70441 3.9743 1.42265C4.8003 1.14088 5.68796 1.15036 6.50864 1.44971C7.32932 1.74906 8.04037 2.32272 8.53875 3.08756C8.59067 3.16712 8.65955 3.23204 8.73958 3.27685C8.8196 3.32166 8.90844 3.34505 8.99859 3.34505C9.08875 3.34505 9.17758 3.32166 9.25761 3.27685C9.33764 3.23204 9.40652 3.16712 9.45844 3.08756C9.9564 2.32141 10.6676 1.74651 11.4888 1.44631C12.31 1.14612 13.1985 1.13627 14.0253 1.41818C14.852 1.7001 15.574 2.2591 16.0865 3.01405C16.5989 3.769 16.8751 4.6806 16.875 5.61651C16.875 9.99132 10.4541 14.6728 9 15.6777Z" fill={color}/>
    </svg>
)

export const ShareIcon = ({color}) => (
    <svg width="18" height="19" viewBox="0 0 18 19" fill="none">
        <path d="M14.6149 12.7803C13.6241 12.7803 12.7313 13.1734 12.1117 13.7987L6.53867 10.6276C6.68746 10.2777 6.76998 9.89762 6.76998 9.5C6.76998 9.10223 6.68746 8.72215 6.53867 8.37237L12.1117 5.20111C12.7313 5.82646 13.6241 6.21973 14.6149 6.21973C16.4815 6.21973 18 4.82465 18 3.10979C18 1.39494 16.4815 0 14.6149 0C12.7484 0 11.2299 1.39508 11.2299 3.10994C11.2299 3.50756 11.3125 3.88764 11.4612 4.23757L5.88829 7.40868C5.26868 6.78333 4.37594 6.39006 3.38507 6.39006C1.5185 6.39006 0 7.78529 0 9.5C0 11.2149 1.5185 12.6099 3.38507 12.6099C4.37594 12.6099 5.26868 12.2168 5.88829 11.5913L11.4612 14.7624C11.3125 15.1124 11.2299 15.4924 11.2299 15.8902C11.2299 17.6049 12.7484 19 14.6149 19C16.4815 19 18 17.6049 18 15.8902C18 14.1753 16.4815 12.7803 14.6149 12.7803ZM12.4642 3.10994C12.4642 2.02043 13.429 1.13401 14.6149 1.13401C15.8008 1.13401 16.7657 2.02043 16.7657 3.10994C16.7657 4.19945 15.8008 5.08587 14.6149 5.08587C13.429 5.08587 12.4642 4.19945 12.4642 3.10994ZM3.38507 11.4759C2.19902 11.4759 1.23418 10.5895 1.23418 9.5C1.23418 8.41049 2.19902 7.52407 3.38507 7.52407C4.57096 7.52407 5.53565 8.41049 5.53565 9.5C5.53565 10.5895 4.57096 11.4759 3.38507 11.4759ZM12.4642 15.8901C12.4642 14.8006 13.429 13.9141 14.6149 13.9141C15.8008 13.9141 16.7657 14.8006 16.7657 15.8901C16.7657 16.9796 15.8008 17.866 14.6149 17.866C13.429 17.866 12.4642 16.9796 12.4642 15.8901Z" fill={color}/>
    </svg>    
)