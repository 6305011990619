import React, { useEffect, useState } from 'react';
import './Tabs.css';
import { useLocation } from 'react-router-dom';
import { getNotesList, createNotes, UpdateNotes, DeleteNotes } from '../../../services';
import DeleteIcon from "./../../../assets/dashboard/delete.svg";
import DeleteModal from '../../../modals/DeleteModal';
import CommonButton from '../../../components/common/CommonButton';
import useToast from '../../../utils/customHooks/useToast';

const Notes = ({ isAdding, setIsAdding }) => {
    
    
   
  let projectDetails = JSON.parse(localStorage.getItem("projectDetail"));
  const token = localStorage.getItem("token");
  
  const location = useLocation();
  const { id } = location.state || {};

  const [data, setData] = useState([]);
  const [selectedData, setSelectedData] = useState(null); // To store clicked item
  const [searchTerm, setSearchTerm] = useState('');
  const [onlyMeFilter, setOnlyMeFilter] = useState(false);
  const [shareOption, setShareOption] = useState(1); // 1 = Only Me, 2 = Team Members, 3 = Both
  const [showDeletePopup, setShowDeletePopup] = useState(false); // To control delete confirmation popup
  const [notesLoader, setnotesLoader] = useState(false)
  const showToast = useToast();
  const [isDropdownOpen, setIsDropdownOpen] = useState(false); // Track dropdown visibility

  useEffect(() => {
    const fetchData = async () => {
      const notesData = await getNotesList(id, projectDetails?.businessId, token);
      if (notesData?.data?.status === 1) {
        setData(notesData?.data?.responseData?.data);
        setSelectedData(notesData?.data?.responseData?.data[0]); // Select first element by default
      }
    };

    if (id) {
      fetchData();
    }
  }, [id]);

  useEffect(()=>{
    if (isAdding){
        setIsAdding(true)
        setSelectedData({ noteTitle: '', notesDescription: '', projectName: projectDetails.projectName, sharing: '', projectId: id });
    }
},[isAdding])

  // Filter data based on search term and 'Only Me' toggle
  const filteredData = data?.filter(item => {
    const matchesSearch = item?.noteTitle?.toLowerCase().includes(searchTerm.toLowerCase());
    const matchesFilter = onlyMeFilter ? item?.sharing : true;
    return matchesSearch && matchesFilter;
  });

  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
  };

  const toggleOnlyMeFilter = () => {
    setOnlyMeFilter(!onlyMeFilter);
  };

  const handleShareChange = (e) => {
    setShareOption(e.target.value);
    setIsDropdownOpen(false)
  };

  const handleTitleChange = (e) => {
    setSelectedData({ ...selectedData, noteTitle: e.target.value });
  };

  const handleDescriptionChange = (e) => {
    setSelectedData({ ...selectedData, notesDescription: e.target.value });
  };

  // Save or Update Note
  const handleSave = async () => {
        setnotesLoader(true);
        const params = {
            projectName: projectDetails?.projectName,
            sharing: shareOption,
            notesDescription: selectedData?.notesDescription,
            noteTitle: selectedData?.noteTitle,
            projectId: id,
        };

        let notesValue;

        if (isAdding) {
            // Create a new note
            const notesCreatedValue = await createNotes(params, projectDetails?.businessId, token);
            notesValue = notesCreatedValue?.data?.responseData; // Get the newly created note
            showToast("Notes created successfully.", "success");
        } else {
            // Update existing note
            const notesUpdatedValue = await UpdateNotes(params, selectedData?.id, token);
            notesValue = notesUpdatedValue?.data?.responseData; // Get the updated note
            showToast("Notes updated successfully.", "success");
        }

        // Refetch data after creation or update
        const notesData = await getNotesList(id, projectDetails?.businessId, token);
        if (notesData?.data?.status === 1) {
            setData(notesData?.data?.responseData?.data);
            
            // Set selectedData to the newly created or updated note
            setSelectedData(notesValue);
        }
        
        setnotesLoader(false);
        setIsAdding(false);
    };


  // Cancel operation
  const handleCancel = () => {
    setIsAdding(false);
  };

  // Delete note functionality
  const handleDelete = async () => {
    if (selectedData && selectedData.id) {
      await DeleteNotes(selectedData.id, token);
      setShowDeletePopup(false);

      // Refetch data after deletion
      const notesData = await getNotesList(id, projectDetails?.businessId, token);
      if (notesData?.data?.status === 1) {
        setData(notesData?.data?.responseData?.data);
        setSelectedData(notesData?.data?.responseData?.data); // Reset to the first element after delete
      }
    }
  };
    



  const getShareLabel = (sharing) => {
    
    switch (sharing) {
        case '1':
            return 'Me Only';
        case '2':
            return 'Team';
        // case '3':
        //     return 'Team + Client';
        default:
            return 'Team + Client'
    }
  };

  const toggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen); // Toggle dropdown visibility
   };



  return (
    <div className="tab-content">
      {/* Top Controls: Dropdown, Search, Filter */}
      <div className="top-controls">
        <select className="dropdown">
          <option>All Projects</option>
          <option>Project A</option>
          <option>Project B</option>
        </select>
        <input
          type="text"
          placeholder="Search..."
          value={searchTerm}
          onChange={handleSearchChange}
          className="search-input"
        />
        <button className="filter-btn" onClick={toggleOnlyMeFilter}>
          {onlyMeFilter ? '' : 'Filter'}
        </button>
      </div>

      {/* Sidebar and Main Content */}
      <div className="content-container">
        {/* Sidebar: List of data */}
            <div className="sidebar">
                {filteredData.map(item => (
                <div
                    key={item.id}
                    className={`sidebar-item ${selectedData?.id === item.id ? 'active' : ''}`}
                    onClick={() => { setSelectedData(item); setIsAdding(false); }}
                >
                    <div className="sidebar-item-content">
                    <p className="sidebar-item-title">{item.noteTitle}</p>
                    <p className="sidebar-item-subtitle">{item.notesDescription.slice(0, 25)}...</p>
                    <p className="sidebar-item-time">2 min ago</p>
                    </div>
                    <div className="sidebar-item-icon">
                    <img
                        src={DeleteIcon}
                        style={{ width: '22px', height: '24px' }}
                        alt="Delete"
                        onClick={() => setShowDeletePopup(true)}
                    />
                    </div>
                </div>
                ))}
            </div>

            {/* Main Content Area */}
            <div className="main-area">
                {selectedData ? (
                <>
                    {/* Top Section: Editable Title, Only Me Dropdown, and Share Button */}
                    <div className="main-header">
                    <input
                        type="text"
                        value={selectedData.noteTitle}
                        onChange={handleTitleChange}
                        className="editable-title"
                        placeholder="Enter Title"
                    />
                   <div className="controls">
                        {/* Selected share option displayed next to the button */}
                        <span className="selected-share-option">{getShareLabel(selectedData?.sharing)}</span>

                        {/* Share button to open dropdown */}
                        <button className="share-btn" onClick={toggleDropdown}>
                            Share
                        </button>

                        {/* Conditional rendering of dropdown */}
                        {isDropdownOpen && (
                            <select value={shareOption} onChange={handleShareChange} className="dropdown">
                                <option value={1}>Me Only</option>
                                <option value={2}>Team</option>
                                <option value={3}>Team + Client</option>
                            </select>
                        )}
                    </div>
                    </div>

                    {/* Editable Description Section */}
                    <div className="main-body">
                    <textarea
                        value={selectedData.notesDescription}
                        onChange={handleDescriptionChange}
                        className="editable-description"
                        placeholder="Enter description here"
                    />
                    </div>
                </>
                ) : (
                <div className="main-body">
                    <p>Select an item to see details</p>
                </div>
                )}
            </div>
     </div>



      {/* Delete confirmation popup */}
      {showDeletePopup && (
            <DeleteModal
                title={"Are you sure you want to delete this note?"}
                isOpen={showDeletePopup}
                onClose={() => setShowDeletePopup(false)}
                onSure={handleDelete}
            />
      )}

        <div className="contract_main_button">
            <CommonButton
                label="Cancel"
                fontSize="14px"
                fontWeight="700"
                className="dash_common_btn white_bg_btn"
                icon={false}
                onClick={handleCancel}
            />
            <CommonButton
                label="Save"
                fontSize="14px"
                fontWeight="700"
                className="dash_common_btn"
                icon=""
                isLoad={notesLoader}
                onClick={handleSave}
            />
        </div>
    </div>
    
  );
};

export default Notes;
