import React, { useEffect, useState, useRef } from "react";
import { Document, Page, pdfjs} from "react-pdf";
import SignatureModal from "../components/SignaturModal";
import { PDFDocument, rgb } from "pdf-lib";
import "react-datepicker/dist/react-datepicker.css";
import { useNavigate } from "react-router-dom";
import { jwtDecode } from "jwt-decode";
import _logo from "../assets/logos/xora_logo.svg";
import emailIcon from "../assets/logos/email.svg";
import SuccessImg from '../assets/img/ic_green.png'
import {
  _updateDealRoomService,
  _updateDealRoomServiceCr,
  retrivePdfServices,
  retriveSignersServices,
  retriveSignersCRServices,
  updatePDFService,
  uploadToS3Service,
  updatePDFServiceForCr,
} from "../services";
import {
  base64ImageToFile,
  hexToRgbValues,
  isBase64Image,
  pdfBlobUrlToFile,
  clientformatDate,
  formatDate2
} from "../utils/helper";
import useToast from "../utils/customHooks/useToast";
import CommonButton from "../components/common/CommonButton";


console.log(pdfjs.version, 'pdfjs.version')


pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const colors = [
  "#A9A9A9",
  "#9370DB",
  "#28A745",
  "#5BC0DE",
  "#007BFF",
];



const getPoint = (pos, pageNumber, type, domSize) => {
  if (type === 'Y') {
    const offset = (domSize.h * pageNumber) - (pos + 30);
    return (offset * domSize.ch) / domSize.h;
  } else {
    return (pos * domSize.cw) / domSize.w;
  }
};


const getYPoint = (position, pageNumber, domSize) => {
  if (position > domSize.h) {
    return (position - domSize.h * (pageNumber - 1) - 10);
  }
  return position - 10;
};


const getYPointForMobileY = (field, pageSizeInPx) => {
  const scaleFactor = pageSizeInPx.height / (field.pageHeight / 1.53);
  const yOffset = field.y > field.pageHeight ? (field.pageHeight * field.pageNumber) + field.y : field.y;
  return yOffset * scaleFactor + (field.pageNumber * 6);
};


const getYPointForMobileX = (field, pageSizeInPx) => {
    return (pageSizeInPx.width / (field.pageWidth / 1.5)) * field.x
};


const getPointForMobile2 = (field, pageSizeInPx) => {
  return (field.pageHeight - ((field.y * 1.55) + 30))
};

const getPointForMobile3 = (field, pageSizeInPx) => {
  return field.x * 1.55
};




const ClientSignatureTemplate = () => {
  const sigCanvas = useRef({});
  const [numPages, setNumPages] = useState(null);
  const [magicFieldData, setMagicFieldData] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isModalOpen2, setIsModalOpen2] = useState(false);
  const [selectedField, setSelectedField] = useState(null);
  const [isCR, setIsCR] = useState(false);
  const [editingTextField, setEditingTextField] = useState(null);
  const [textInputValue, setTextInputValue] = useState(""); 
  const [editingDateField, setEditingDateField] = useState(null);
  const [selectedDate, setSelectedDate] = useState(null);
  const [userToken, setUserToken] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [signersDetails, setSignersDetails] = useState([])
  const pdfUrl = localStorage.getItem("pdfUrl") || "";

  const [dealRoomData, setDealRoomData] = useState(null);
  const [dynamicColors, setDynamicColors] = useState({});
  const [signers, setSigners] = useState([])
  const [pdfWidth, setPdfWidth] = useState('')
  const [pdfHeight, setPdfHeight] = useState('')
  const [isMobile, setIsMobile] = useState(false)
  const [renderPdf, setRenderPdf] = useState("");
  const [isDocumentSent, setIsDocumentSent] = useState(false)
  const [pageSizeInPx, setPageSizeInPx] = useState({})
  const [tempSignatureData, setTempSignatureData] = useState('')
  const [tempDate, setTempDate] = useState(formatDate2(new Date().getTime()))
  const [isCanvasToch, setIsCanvasToch] = useState(false)
  const navigate = useNavigate()
  const showToast = useToast();


  useEffect(() => {
    pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.mjs`;
    const urlParams = new URLSearchParams(window.location.search);
    const token = urlParams.get("templateToken");
  
    if (token) {
      setUserToken(token);
      const SECRET_KEY = process.env.REACT_APP_SECRET_KEY;
      const decodeReferToken = jwtDecode(token, { header: SECRET_KEY });
      setDealRoomData(decodeReferToken);
      console.log(decodeReferToken, 'decodeReferTokendecodeReferTokendecodeReferToken')

    }
  }, []);

  useEffect(() => {
    if (dealRoomData !== null && dealRoomData !== "null") {
      try {
        let _service = dealRoomData.createRequest ? retriveSignersCRServices : retriveSignersServices;
        if(dealRoomData.createRequest){
          setIsCR(true)
        }

        let query = dealRoomData.createRequest || dealRoomData?.dealroomId;
        _service(query).then((res) => {
          if (res.data.status === 1) {
            setRenderPdf(res.data.responseData.pdfRecord.rawpdfFile);
            setIsMobile(res.data.responseData.isMobile)
            const firstMagicField = res.data.responseData.magicField[0];

            let isMobilePdf = false

            if (firstMagicField?.docWidth && firstMagicField?.docHeight) {
              setPdfWidth(firstMagicField.docWidth);
              setPdfHeight(firstMagicField.docHeight);
              isMobilePdf = true
            } else {
              setPdfWidth('');
              setPdfHeight('');
            }

            const transformedData = transformApiData(
              res.data.responseData.magicField,
              isMobile
            );
        
            const updatedSigners = res.data.responseData.signers.map(signer => {
              if (signer.emailId === dealRoomData.emaiId) {
                  return {
                      ...signer,
                      status: 8
                  };
              }
              return signer;
            });
            try {
              let obj = {
                dealroomId: dealRoomData.dealroomId,
                signers:updatedSigners,
              };
              _updateDealRoomService(dealRoomData.token, dealRoomData.dealroomId, obj).then((res) => {
       
              });
            } catch (error) {
              
            }
            setSigners(updatedSigners || [])
            setMagicFieldData(transformedData || null);
          }
        });
      } catch (error) {}
    }
  }, [dealRoomData]);



  const reverseTransformMagicFields = (transformedData) => {
    const reversed = [];
    Object.keys(transformedData).forEach((emailId) => {
      Object.keys(transformedData[emailId]).forEach((fieldName) => {
        const field = transformedData[emailId][fieldName];
        reversed.push({
          x: Math.trunc(field.x),
          y: Math.trunc(field.y),
          cm_y: field.cm_y,
          domPageSize: field.domPageSize,
          cm_x: field.cm_x,
          width: field.width,
          height: field.height,
          pageHeight: field.pageHeight,
          pageNumber: field.pageNumber,
          pageWidth: field.pageWidth,
          type: field.type,
          fieldType: field.fieldType,
          value: field.title,
          name: field.title,
          xPercent: field.xPercent,
          yPercent: field.yPercent,
          isSign:
            field.isSign === true
              ? true
              : dealRoomData.emaiId === emailId
              ? true
              : false,
          assignTo: {
            emailId: emailId,
            name: field.name,
            status: dealRoomData.emaiId === emailId ? 19 : field.status,
          },
        });
      });
    });
    return reversed;
  };

  const transformApiData = (apiData, isMobile) => {
    const transformed = {};
    if (apiData && apiData.length > 0) {
      apiData.forEach((field) => {
        const emailId = field.assignTo?.emailId;
        if (!transformed[emailId]) {
          transformed[emailId] = {};
        }

        transformed[emailId][field.name] = {
          x: field.x,
          y: field.y,
          cm_y: field.cm_y,
          domPageSize: field.domPageSize,
          cm_x: field.cm_x,
          width: field.width,
          height: field.height,
          pageHeight: field.pageHeight,
          pageWidth: field.pageWidth,
          pageNumber: field.pageNumber,
          type: field.type,
          fieldType: field.fieldType,
          opacity: 0.75,
          title: field.value,
          emailId: field.assignTo.emailId,
          status: field.assignTo.status,
          signature:
            (field.type === 1 || field.type === 2 ) && isBase64Image(field.value) ? field.value : null,
          name: field.assignTo.name,
          isSign: field.isSign,
          xPercent: field.xPercent,
          yPercent: field.yPercent
        };
        // }
      });
    }
    return transformed;
  };

  const onDocumentLoadSuccess = ({ numPages }) => {
    setNumPages(numPages);
  };

  const handleBoxClick = (title, emailId, type) => {
    if (type === 1 || type === 2) {
      setSelectedField({ title, emailId });
      setIsModalOpen(true);
    }
    if (type === 3) {
      setEditingTextField({ title, emailId });
      setTextInputValue(title); // Set initial value of the input field
    }
    if (type === 4) {
      handleDateFieldBlur(title, emailId, formatDate(new Date()));
    }
  };

  const handleModalClose = () => {
    setIsModalOpen(false);
    setIsModalOpen2(false);
    setSelectedField(null);
  };

  const handleSignatureSave = (signatureData) => {
    setMagicFieldData((prevData) => {
      const newData = { ...prevData };
      Object.keys(newData).forEach((key) => {
        Object.keys(newData[key]).forEach((fieldKey) => {
          const field = newData[key][fieldKey];
          if (
            field.title === selectedField.title &&
            field.emailId === selectedField.emailId
          ) {
            field.signature = signatureData;
            field.isSign = true;
            field.title = signatureData;
          }
        });
      });
      localStorage.setItem("test", JSON.stringify(newData)); // Update localStorage
      return newData;
    });

    handleModalClose();
  };



  const handleSignatureSave2 = async (signatureData) => {
    try {
      const _signatureData = await base64ImageToFile(signatureData, `mysign.png`);
      const formData = new FormData();
      formData.append('image', _signatureData);
  
      const res = await uploadToS3Service(formData);
      setTempSignatureData(res.data.responseData.imageUrl)
      
      handleModalClose();
    } catch (error) {
      console.error("Error saving signature:", error);
    }
  };



  
  const handleTextFieldChange = (e) => {
    setTextInputValue(e.target.value);
  };

  const handleTextFieldBlur = (title, emailId) => {
    setMagicFieldData((prevData) => {
      const newData = { ...prevData };
      Object.keys(newData).forEach((key) => {
        Object.keys(newData[key]).forEach((fieldKey) => {
          const field = newData[key][fieldKey];
          if (field.title === title && field.emailId === emailId) {
            field.title = textInputValue;
            field.isSign = true;
          }
        });
      });
      localStorage.setItem("test", JSON.stringify(newData)); // Update localStorage
      return newData;
    });
    setEditingTextField(null);
  };

  const handleDateChange = (date) => {
    setSelectedDate(date);
  };

  const handleDateFieldBlur = (title, emailId, curentDate) => {
    setMagicFieldData((prevData) => {
      const newData = { ...prevData };
      Object.keys(newData).forEach((key) => {
        Object.keys(newData[key]).forEach((fieldKey) => {
          const field = newData[key][fieldKey];
          if (field.title === title && field.emailId === emailId) {
            field.title = curentDate;
            field.isSign = true; // Set selected date as title
          }
        });
      });
      localStorage.setItem("test", JSON.stringify(newData)); // Update localStorage
      return newData;
    });
    setEditingDateField(null);
  };

  const getColorForEmail = (emailId) => {
    if (dynamicColors[emailId]) {
      return dynamicColors[emailId];
    }

    // Find the first available color that isn't already used
    for (let color of colors) {
      if (!Object.values(dynamicColors).includes(color)) {
        const newDynamicColors = { ...dynamicColors, [emailId]: color };
        setDynamicColors(newDynamicColors);
        return color;
      }
    }

    // If all colors are used, reuse colors (could be improved based on your requirements)
    const colorIndex = Object.keys(dynamicColors).length % colors.length;
    const newDynamicColors = {
      ...dynamicColors,
      [emailId]: colors[colorIndex],
    };
    setDynamicColors(newDynamicColors);
    return colors[colorIndex];
  };

  const signersDetailsHandler = (email, color) => {
    // Create a copy of the existing signersDetails array
    let temp = [...signersDetails];

    // Check if the email already exists in the array
    const emailExists = temp.some(signer => signer.email === email);

    if (!emailExists) {
      // If the email does not exist, add the new data
      let objData = {
        email: email,
        color: color
      };
      temp.push(objData);
      setSignersDetails(temp);
    }
  };

  const formatDate = (date) => {
    const day = date.getDate().toString().padStart(2, "0");
    const monthNames = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];
    const month = monthNames[date.getMonth()];
    const year = date.getFullYear();
    return `${month} ${day}, ${year}`;
  };

  const renderBoxes = (pageIndex) => {
    if (!magicFieldData) return [];
    return Object.keys(magicFieldData)
      .flatMap((key) => {
        return Object.keys(magicFieldData[key]).map((fieldKey) => {
          const field = magicFieldData[key][fieldKey];
          const {
            x,
            y,
            cm_y,
            cm_x,
            domPageSize,
            width,
            height,
            pageHeight,
            pageNumber,
            title,
            emailId,
            type,
            signature,
            isSign,
          } = field;


          if (pageNumber - 1 !== pageIndex) return null;

          const boxColor = getColorForEmail(emailId);

          if(boxColor){
            signersDetailsHandler(emailId, boxColor)
          }



          const boxStyle = {
            width: `${120}px`,
            height: `${30}px`,
            backgroundColor: isSign ? "transparent" : boxColor,
            border: isSign ? "none" : boxColor,
            cursor: title === "Client Signature" ? "pointer" : "default",
          };

          if(isMobile){
            boxStyle.top = `${getYPointForMobileY(field, pageSizeInPx)}px`
            boxStyle.left = `${getYPointForMobileX(field, pageSizeInPx)}px`
          }else{
            boxStyle.top = `${getYPoint(cm_y, pageNumber, domPageSize)}px`
            boxStyle.left = `${cm_x - 20}px`
          }

          return (
            <div
              key={fieldKey}
              id={cm_y}
              className="mm_box"
              style={boxStyle}
              onClick={() =>
                dealRoomData.emaiId === emailId
                  ? handleBoxClick(title, emailId, type)
                  : ""
              }
            >
              {signature ? (
                <img
                  src={signature}
                  alt="Signature"
                  style={{
                    width: 'auto',
                    height: '100%',
                    objectFit: "contain",
                  }}
                />
              ) : editingTextField &&
                editingTextField.title === title &&
                editingTextField.emailId === emailId ? (
                <input
                  type="text"
                  value={textInputValue}
                  onChange={handleTextFieldChange}
                  onBlur={() => handleTextFieldBlur(title, emailId)}
                  style={{
                    width: "100%",
                    height: "100%",
                    border: "none",
                    textAlign: "center",
                  }}
                />
              ) : editingDateField &&
                editingDateField.title === title &&
                editingDateField.emailId === emailId ? (
                <span onBlur={() => handleDateFieldBlur(title, emailId, formatDate(new Date()))}>
                  {formatDate(new Date())}
                </span>
              ) : (
                title
                // `X-${Math.round(field.x)} Y-${Math.round(field.y)}`
              )}
            </div>
          );
        });
      })
      .filter(Boolean);
  };

  function determineFinalStatus(signers) {
    const statusCounts = { 6: 0, 8: 0, 17: 0, 19: 0, 18:0 };
    let finalStatus;
    signers.forEach(signer => {
        if (statusCounts.hasOwnProperty(signer.status)) {
            statusCounts[signer.status]++;
        }
    });

    const totalSigners = signers.length;
    if (statusCounts[19] === totalSigners) {
      finalStatus = 11;
    } else if (statusCounts[19] > 0) {
        finalStatus = (statusCounts[19] === totalSigners) ? 19 : 17;
    } else if (statusCounts[8] === totalSigners) {
      finalStatus = 8
    } else if (statusCounts[8] > 0) {
        finalStatus = 8;
    } else if (statusCounts[6] === totalSigners) {
        finalStatus = 6;
    } else if (statusCounts[6] === totalSigners) {
        finalStatus = 6;
    } else if (statusCounts[18] > 0) {
        finalStatus = 18;
    }

    return finalStatus;
}

const handleDownloadPdf = async () => {
  setIsLoading(true)
  try {
    const existingPdfBytes = await fetch(dealRoomData?.rawpdfFile).then(
      (res) => res.arrayBuffer()
    );
    const pdfDoc = await PDFDocument.load(existingPdfBytes);
    const pages = pdfDoc.getPages();

    const embedAndDrawSignature = async (
      signatureData,
      page,
      x,
      y,
      targetWidth,
      targetHeight
    ) => {
      try {
        const pngImage = await pdfDoc.embedPng(signatureData);
    
        // Get the original width and height of the image
        const { width: originalWidth, height: originalHeight } = pngImage.scale(1);
    
        // Calculate the aspect ratio
        const aspectRatio = originalWidth / originalHeight;
    
        let width = targetWidth;
        let height = targetHeight;
    
        // Adjust the width and height to maintain the aspect ratio
        if (width && height) {
          if (width / height > aspectRatio) {
            width = height * aspectRatio;
          } else {
            height = width / aspectRatio;
          }
        } else if (width) {
          height = width / aspectRatio;
        } else if (height) {
          width = height * aspectRatio;
        } else {
          // If neither width nor height is provided, use the original size
          width = originalWidth;
          height = originalHeight;
        }
    
        page.drawImage(pngImage, {
          x,
          y,
          width,
          height,
        });
      } catch (error) {
        console.error("Error embedding image:", error);
      }
    };
    

    Object.keys(magicFieldData).forEach(async (key) => {
      Object.keys(magicFieldData[key]).forEach(async (fieldKey) => {
        const field = magicFieldData[key][fieldKey];
        const {
          x,
          y,
          cm_x,
          cm_y,
          domPageSize,
          width,
          height,
          pageNumber,
          signature,
          title,
          emailId,
          isSign,
        } = field;
        const page = pages[pageNumber - 1];
        const [r, g, b] = hexToRgbValues(dynamicColors[emailId]);


        if(isMobile){
          if (signature) {
            await embedAndDrawSignature(
              signature,
              page,
              // getPoint(cm_x, pageNumber, 'X', domPageSize),
              // getPoint(cm_y, pageNumber, 'Y', domPageSize),
              getPointForMobile3(field, pageSizeInPx),
              getPointForMobile2(field, pageSizeInPx),
              width,
              height
            );
          } else {
            if (isSign === false) {
              page.drawRectangle({
              x: getPointForMobile3(field, pageSizeInPx),
              y: getPointForMobile2(field, pageSizeInPx),
              width: 120,
              height: 30,
              color: rgb(r, g, b),
              opacity: 0.75,
              });
            }
            page.drawText(title, {
              x: getPointForMobile3(field, pageSizeInPx) + 5,
              y: getPointForMobile2(field, pageSizeInPx) + 15,
              size: 12,
              color: rgb(0, 0, 0),
            });
          }
  
        }else{
          if (signature) {
            await embedAndDrawSignature(
              signature,
              page,
              getPoint(cm_x, pageNumber, 'X', domPageSize),
              getPoint(cm_y, pageNumber, 'Y', domPageSize),
              width,
              height
            );
          } else {
            if (isSign === false) {
              page.drawRectangle({
              x: getPoint(cm_x, pageNumber, 'X', domPageSize),
              y: getPoint(cm_y, pageNumber, 'Y', domPageSize),
              width: 120,
              height: 30,
              color: rgb(r, g, b),
              opacity: 0.75,
              });
            }
            page.drawText(title, {
              x: getPoint(cm_x, pageNumber, 'X', domPageSize) + 5,
              y: getPoint(cm_y, pageNumber, 'Y', domPageSize) + 15,
              size: 12,
              color: rgb(0, 0, 0),
            });
          }

          
        }

        
        



      });
    });

    const pdfBytes = await pdfDoc.save();
    const blob = new Blob([pdfBytes], { type: "application/pdf" });
    const updatedPDFUrl = URL.createObjectURL(blob);
    const pdfFile = await pdfBlobUrlToFile(updatedPDFUrl, `contract.pdf`);

    // const pdfURL = URL.createObjectURL(pdfFile);
    // window.open(pdfURL, '_blank');


    const formData = new FormData();

    formData.append("updatepdfFile", pdfFile);
    formData.append("emailId", dealRoomData?.emaiId);
    formData.append("sendToPdfId", dealRoomData?.sendToPdfId);
    const reversedData = reverseTransformMagicFields(magicFieldData);
    const updatedSigners = signers.map((item, index) => {
        return {
          ...item,
          status: item.emailId === dealRoomData.emaiId ? 19 : item.status
        }
    })
    const finalStatus = determineFinalStatus(updatedSigners);


let desiredIndex = updatedSigners.findIndex(__signer => __signer.emailId === dealRoomData?.emaiId);

let _updatedSignerList = updatedSigners.map((__signer, index) => {
  if (index === desiredIndex) {
    return {
      ...__signer,
      signUrl: tempSignatureData,
      signDate: tempDate,
    };
  } else {
    return __signer;
  }
});

    if (userToken !== "") {
      try {

        if(isCR){
          updatePDFServiceForCr(dealRoomData?.sendToPdfId, formData).then((res) => {
            if (res.data.status === 1) {
              let obj = {
                status : finalStatus,
                magicField: reversedData,
                signedOnDate: new Date().toISOString(),
                signers: _updatedSignerList,
                createRequestId: dealRoomData.createRequest
              };
  
              _updateDealRoomServiceCr(dealRoomData.token, obj.createRequestId || obj.dealroomId, obj).then((res) => {
                setTimeout(() => {
                  setIsLoading(false)
                  setIsDocumentSent(true)
                  setRenderPdf('');
                  setIsCR(false);
                  localStorage.removeItem('isCR');
                },1000)
              });
            }
          });

        }else{
          updatePDFService(dealRoomData?.sendToPdfId, formData).then((res) => {
            if (res.data.status === 1) {
              let obj = {
                status : finalStatus,
                magicField: reversedData,
                signedOnDate: new Date().toISOString(),
                signers: _updatedSignerList,
                dealroomId: dealRoomData.dealroomId
              };

              _updateDealRoomService(dealRoomData.token, obj.createRequestId || obj.dealroomId, obj).then((res) => {
                setTimeout(() => {
                  setIsLoading(false)
                  setIsDocumentSent(true)
                  setRenderPdf('');
                  setIsCR(false);
                  localStorage.removeItem('isCR');
                },1000)


                
              });
            }
          });
        }


        
      } catch (error) {
        setIsLoading(false)
      }
    }
    } catch (error) {
      setIsLoading(false)
    }
};


useEffect(() => {
  if(isDocumentSent){
    setTimeout(() => {
      console.log('0oooooo')
      navigate('/');
    }, 3000)
  }
}, [isDocumentSent])




const handlePageLoadSuccess = (page, index) => {
  setPageSizeInPx({
    width: page.width,
    height: page.height,
  })
};



const handleCanvasChange = (data) => {
  setIsCanvasToch(true);
}

const clearSignature = () => {
  sigCanvas.current.clear();
  setIsCanvasToch(false)
};


  return (
    <div className="pdf-section-wrapper">

      <div className="logo-section-wrapper">
        <div className="logo-section">
          <div className="section-1">
            <img src={_logo} alt="" style={{ marginLeft: "20px" }} />
            <div className="user-email-section">
              <img src={emailIcon} alt="emailIcon" width={30} height={30}/>
              <div>{dealRoomData?.emaiId}</div>
            </div>


            <div className="other_btn_sign">
              {tempSignatureData ? <label className="for_sign_underline">Signature</label> : ''}
                {tempSignatureData ? <img onClick={() => setIsModalOpen2(true)} src={tempSignatureData} className="tempSignatureData" /> : <span onClick={() => setIsModalOpen2(true)}  className="btn btn-dark">Add Signature</span>}
           
            </div>
          </div>
          {(isDocumentSent === false && tempSignatureData) ? <CommonButton label="Send Document" fontSize="14px" fontWeight="700" className="dash_common_btn" icon={false} onClick={handleDownloadPdf} isLoad={isLoading}/> : ''}
        </div>
      </div>

      <div className="document-view_inner">

      {isDocumentSent === false && <div className="signers-color-details-section">
            {
              signersDetails &&
              signersDetails.length > 0
              && signersDetails.map((detail, i) => (
                <div className="signer-color-details">
                  <div className="color-box" style={{backgroundColor: detail.color}}>
                  </div>
                  <div>
                      {detail.email}
                  </div>
                </div>
              ))
            }
    </div>}
        <div className="document-view">
        {renderPdf !== "" ? (
            <Document file={renderPdf} onLoadSuccess={onDocumentLoadSuccess}>
              {Array.from(new Array(numPages), (el, index) => (
                <Page
                  className={`page_id_${index + 1}`}
                  key={`page_${index + 1}`}
                  pageNumber={index + 1}
                  renderAnnotationLayer={false}
                  renderTextLayer={false}
                  onLoadSuccess={(page) => handlePageLoadSuccess(page, index)}
                  scale={1.33}>
                  {
                    pageSizeInPx.width ? renderBoxes(index) : ''
                  }
                </Page>
              ))}
            </Document>
          ) : (
            <div className="conatiner">
              <div style={{ width: "100%", height: "92vh" }}>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    textAlign: "center",
                    alignSelf: "center",
                    alignItems: "center",
                    height: "100vh",
                  }}
                  className="client-signature-status"
                >
                  {isDocumentSent ? 
                  <div>
                    <img src={SuccessImg} alt="success" width={300} height={300}/>
                    <h6 className="mt-2">Document sent Sucessfully</h6>
                  </div>
                  : 'NO RECORD FOUND'}
                </div>
              </div>

         
            </div>
          )}
          {isModalOpen && (
            <SignatureModal
              isOpen={isModalOpen}
              onClose={handleModalClose}
              onSave={handleSignatureSave}
            />
          )}

        {isModalOpen2 && (
            <SignatureModal
              isOpen={isModalOpen2}
              onClose={handleModalClose}
              onSave={handleSignatureSave2}
            />
          )}


          {
            !isDocumentSent ? signers.filter(k => k.emailId == dealRoomData?.emaiId).map(item => (
              <div key={item.emailId} className="cm_singer_box">
               
               <div>
                {tempSignatureData ? <img onClick={() => setIsModalOpen2(true)} src={tempSignatureData} className="tempSignatureData" /> : <span onClick={() => setIsModalOpen2(true)}  className="btn btn-dark">Add Signature</span>}
                {tempSignatureData ? <div className="for_sign_underline">Signature</div> : ''}
               </div>
               <div>
                <label> {tempDate}</label>
                
               </div>
              </div>
            )) : ''
          }



        </div>

   
      </div>

     
    </div>
  );
};

export default ClientSignatureTemplate;
