// Notes.js
import React from 'react';
import './Tabs.css';

const Tasks = () => {
  return (
    <div className="tab-content">
      coming soon with task features
    </div>
  );
};

export default Tasks;
