import React, { useEffect, useState } from "react";
import CommonButton from "../../../components/common/CommonButton";
import filterIcon from "./../../../assets/dashboard/filter_icon.svg";
import plusIcon from "./../../../assets/dashboard/plus.svg";
import searchIcon from "./../../../assets/dashboard/Search.svg";
import threeDot from "./../../../assets/logos/three-dot.svg";
import InvoiceMain from "./invoiceMain";
import { deleteInvoiceService, getInvoiceList, getInvoiceOverview, updateInvoiceService } from "../../../services";
import { invoiceFilterStatus } from "../../../utils/constant";
import ProjectFilter from "../../../modals/DealroomFilter";
import ActionModal from "../../../modals/ActionModal";
import { useSelector } from "react-redux";
import useToast from "../../../utils/customHooks/useToast";
import { useLocation } from "react-router-dom";

export default function Invoices() {
  const location = useLocation();
  const { id } = location.state || {};
  const showToast = useToast();
  const [currentView, setCurrentView] = useState(false);
  const [invoiceList, setInvoiceList] = useState([]);
  const [filteredInvoiceList, setFilteredInvoiceList] = useState([]);
  const [invoiceOverview, setInvoiceOverview] = useState({});
  const [closeInvoice, setCloseInvoice] = useState(localStorage.getItem("closeInvoiceMain"));
  const [isActionModalOpen, setIsActionModalOpen] = useState(false);
  const [actionTitle, setActionTitle] = useState('');
  const [listId, setListId] = useState('');
  const [currentPdf, setCurrentPdf] = useState('');
  const [searchTerm, setSearchTerm] = useState("");
  const [debouncedSearchTerm, setDebouncedSearchTerm] = useState("");
  const [filter, setFilter] = useState({
    status: {},
  });
  const [showProjectFilter, setShowProjectFilter] = useState(false);
  const [isFilterRun, setIsFilterRun] = useState(false);
  const [filterError, setFilterError] = useState(false);
  const [searchError, setSearchError] = useState(false)
  const [renderList, setrenderList] = useState(false)

  const [savedFilters, setSavedFilters] = useState(null);

  const _status = useSelector(state => state?.status?.status?.status);

  const initialStatus = [
    ...invoiceFilterStatus.map(item => ({
      statusName: item.label,
      status: false,
    }))
  ];

  useEffect(() => {
    setFilter({
      status: initialStatus.reduce((acc, item) => {
        acc[item.statusName] = item.status;
        return acc;
      }, {}),
    });
  }, [_status]);


  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedSearchTerm(searchTerm);
    }, 300); // Debounce delay

    return () => {
      clearTimeout(handler);
    };
  }, [searchTerm]);

  useEffect(() => {
    applyFilter();
  }, [debouncedSearchTerm, invoiceList, filter]);

  const applyFilter = () => {
    const searchTermLower = debouncedSearchTerm.toLowerCase();
    const statusFiltersActive = Object.values(filter.status).some(value => value);
  
    if (!statusFiltersActive && searchTermLower === "") {
      setFilteredInvoiceList(invoiceList);
      setSearchError(false);
      setFilterError(false);
      return;
    }
  
    if (!statusFiltersActive) {
      setFilteredInvoiceList(invoiceList.filter(invoice => 
        invoice.title.toLowerCase().includes(searchTermLower) || 
        invoice.createBy.toLowerCase().includes(searchTermLower)
      ));
      setSearchError(true);
      setFilterError(false);
      return;
    }
  
    const filtered = invoiceList.filter(invoice => {
      const invoiceStatus = invoiceFilterStatus.find(status => status.id === invoice.status)?.value.toLowerCase();
      
      const statusFilterActive = Object.entries(filter.status)
        .some(([key, value]) => value && key.toLowerCase() === invoiceStatus);
  
      const searchMatch = invoice.title.toLowerCase().includes(searchTermLower) || 
                          invoice.createBy.toLowerCase().includes(searchTermLower);
  
      return statusFilterActive && searchMatch;
    });

    setFilteredInvoiceList(filtered);
    setFilterError(filtered.length === 0 && statusFiltersActive);
    setSearchError(filtered.length === 0 && searchTermLower !== "");
  };


  // const applyFilter = () => {
  //   const searchTermLower = debouncedSearchTerm.toLowerCase();
  
  //   const statusFiltersActive = Object.values(filter.status).some(value => value);
  
  //   if (!statusFiltersActive) {
  //     setFilteredInvoiceList(invoiceList.filter(invoice => 
  //       invoice.title.toLowerCase().includes(searchTermLower) || 
  //       invoice.createBy.toLowerCase().includes(searchTermLower)
  //     ));
  //     setSearchError(true);
  //     setFilterError(false);
  //     return;
  //   }
  
  //   const filtered = invoiceList.filter(invoice => {
  //     const invoiceStatus = invoiceFilterStatus.find(status => status.id === invoice.status)?.value.toLowerCase();
      
  //     const statusFilterActive = Object.entries(filter.status)
  //       .some(([key, value]) => value && key.toLowerCase() === invoiceStatus);
  
  //     const searchMatch = invoice.title.toLowerCase().includes(searchTermLower) || 
  //                         invoice.createBy.toLowerCase().includes(searchTermLower);
  
  //     return statusFilterActive && searchMatch;
  //   });
  //   setFilteredInvoiceList(filtered);
  //   setFilterError(filtered.length === 0);
  //   setSearchError(false)
  // };
  

  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
  };

  const handleProjectFilterClick = () => {
    setShowProjectFilter(true);
  };

  const handleCloseActionModal = () => {
    setActionTitle('');
    setIsActionModalOpen(false);
  };
  const [itemTitle, setItemTitle] = useState("")
  const actionModalHandler = (item) => {    
    if (item.id && item?.pdfRecord?.pdfFile) {
      setItemTitle(item?.title)
      setIsActionModalOpen(true);
      setListId(item?.id);
      setActionTitle(item?.status);
      setCurrentPdf(item?.pdfRecord?.pdfFile);
    }
  };

  const actionHandler = (id, type) => {
    if(type === 2){
      try {
        deleteInvoiceService(id).then(res => {
          if(res.data.status === 1){
            showToast("Invoice deleted successfully.", "success")
            setrenderList(true)
          }else{
            showToast("Error deleting invoice.", "error")
          }
        })
      } catch (error) {
        
      }
    }
    if(type === 1){
      let data = {
        invoiceId:id,
        status:5
      }
      try {
        updateInvoiceService(data).then(res => {
          if(res.data.status === 1){
            showToast("Invoice canceled successfully.", "success")
            setrenderList(true)
          }else{
            showToast("Error updating invoice.", "error")
          }
        })
      } catch (error) {
        
      }
    }
    if(type === 6){
      let data = {
        invoiceId:id,
        status:16
      }
      try {
        updateInvoiceService(data).then(res => {
          if(res.data.status === 1){
            showToast("Invoice paid successfully.", "success")
            setrenderList(true)
          }else{
            showToast("Error updating invoice.", "error")
          }
        })
      } catch (error) {
        
      }
    }
  }

  const handleOpenInvoice = () => {
    setCurrentView(true);
  };

  const handleCancel = () => {
    setCurrentView(false);
    setCloseInvoice(localStorage.getItem("closeInvoiceMain"));
  };

  useEffect(() => {
    const fetchData = async () => {
      const projectDetails = JSON.parse(localStorage.getItem('projectDetail'));
      const projectId = id || '';
      
      const businessId = projectDetails?.businessId || '';
      const token = localStorage.getItem("token");

      if (projectId) {
        try {
          const invoiceListResponse = await getInvoiceList(projectId, token);
          if (invoiceListResponse.data.status === 1) {
            setInvoiceList(invoiceListResponse?.data?.responseData?.data);
            setFilteredInvoiceList(invoiceListResponse?.data?.responseData?.data);
          }

          const invoiceOverviewResponse = await getInvoiceOverview(projectId, businessId, token);
          if (invoiceOverviewResponse.data.status === 1) {
            setInvoiceOverview(invoiceOverviewResponse?.data?.responseData?.invoiceOverview);
          }
        } catch (error) {
          console.error("Error fetching data", error);
        }
      }
    };

    fetchData();
  }, [currentView, renderList]);

  const getStatusText = (statusId) => {    
    const status = invoiceFilterStatus.find(status => status.id === statusId);    
    return status ? { text: (status?.value)?.toLowerCase(), color: status?.color } : { text: "-", color: "#000000" };
  };
  const capitalizeFirstLetter = (text) => {
    if (!text) return '';
    return text.charAt(0).toUpperCase() + text.slice(1).toLowerCase();
  };

  function formatDate(dateStr) {
    const options = { year: 'numeric', month: 'long', day: '2-digit' };
    return new Date(dateStr).toLocaleDateString('en-US', options);
  }

  return (
    <div style={{  overflowY: "scroll", paddingRight: "10px" }} className="invoice_main_div">
      {currentView ? (
        <InvoiceMain onCancel={handleCancel} />
      ) : (
        <>
          <div className="dealroom_detail_upper _flex_end_">
            <div className="dealroom_detail_upper_right _flex_gap_30">
              <CommonButton
                label="Invoice"
                fontSize="14px"
                fontWeight="700"
                className="dash_common_btn"
                icon={plusIcon}
                onClick={handleOpenInvoice}
              />
              <CommonButton
                label="Record Manual/Offline Payment"
                fontSize="14px"
                fontWeight="700"
                className="dash_common_btn"
                icon={false}
              />
            </div>
          </div>

          <div style={{ maxHeight: "450px", overflowY: "auto", margin: "54px 0 40px" }}>
            <table className="project_table dealroom_table" style={{ marginTop: 0 }}>
              <thead>
                <tr>
                  <th>Total Amount</th>
                  <th>Received</th>
                  <th>Outstanding (includes overdue amt. too)</th>
                  <th>Overdue {invoiceOverview?.overdueDays} Days</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>₹ {invoiceOverview?.totalAmount}</td>
                  <td>₹ {invoiceOverview?.receivedAmount}</td>
                  <td>₹ {invoiceOverview?.outstandingAmount}</td>
                  <td>₹ {invoiceOverview?.overdueAmount}</td>
                </tr>
              </tbody>
            </table>
          </div>

          <div className="dealroom_detail_upper _flex_row_spacebtw_">
            <div className="dealroom_detail_upper_left _flex_gap_30">
              <div className="dashboard_upper_input">
                <input
                  type="text"
                  placeholder="Search"
                  value={searchTerm}
                  onChange={handleSearchChange}
                />
                <img src={searchIcon} alt="searchIcon" />
              </div>
              <CommonButton
                label="Filter"
                fontSize="14px"
                fontWeight="700"
                className="dash_common_btn white_bg_btn"
                icon={filterIcon}
                onClick={handleProjectFilterClick}
              />
              {showProjectFilter && (
                <ProjectFilter 
                  handleFilterClose={() => { setShowProjectFilter(false); setIsFilterRun(true); }}
                  filterBy={filter}
                  invoiceFilter={filter}
                  setFilterBy={setFilter}
                  isInvoice={true}
                  saveData={savedFilters}
                />
              )}
            </div>
          </div>

          <h2 className="dashboard_heading">Invoice Details</h2>
          <p className="font_14">
            Reminders for documents will be sent every 2 days until signed.
          </p>

          <div>
          <table className="project_table dealroom_table">
              <thead>
                <tr>
                  <th>S.No.</th>
                  <th>Date</th>
                  <th>Title</th>
                  <th>Amount</th>
                  <th>Status</th>
                  <th>Sent By</th>
                  <th>Due Date</th>
                  <th>Actions</th>
                </tr>
              </thead>
              <tbody>
                {invoiceList.length === 0 ? (
                  <tr>
                    <td colSpan="8" className="no-data-message">
                      No invoices available.
                    </td>
                  </tr>
                ) : filterError ? (
                  <tr>
                    <td colSpan="8" className="no-data-message">
                      No results match the filter criteria.
                    </td>
                  </tr>
                ) : searchError ? (
                  <tr>
                    <td colSpan="8" className="no-data-message">
                      No results match the search criteria.
                    </td>
                  </tr>
                ) : (
                  filteredInvoiceList.map((invoice, index) => {
                    const { text, color } = getStatusText(invoice?.status);
                    return (
                      <tr key={invoice?.id}>
                        <td>{index + 1}</td>
                        <td>{formatDate(invoice.created_at)}</td>
                        <td>{invoice.title}</td>
                        <td>₹ {invoice.invoiceAmount.totalAmount.toFixed(2)}</td>
                        <td style={{ color: color }}>{capitalizeFirstLetter(text)}</td>
                        <td>{invoice.createBy}</td>
                        <td>{formatDate(invoice.dueDate)}</td>
                        <td style={{ cursor: 'pointer' }}>
                          <span onClick={() => actionModalHandler(invoice)}>
                            <img src={threeDot} alt="three dot" />
                          </span>
                        </td>
                      </tr>
                    );
                  })
                )}
              </tbody>
            </table>
          </div>

          {isActionModalOpen && <ActionModal
            isOpen={isActionModalOpen}
            title={actionTitle}
            isCRView={true}
            onClose={handleCloseActionModal}
            dealRoomID={listId}
            onSave={actionHandler}
            pdf={currentPdf}
            isInvoice={true}
            itemTitle={itemTitle}
          />}
        </>
      )}
    </div>
  );
}
