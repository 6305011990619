export const ROUTES = {
  HOME: "/",
  SIGNIN: "signin",
  SIGNUP: "signup",
  SIGNUP_EMAIL: "email",
  OTP_VERIFICATION: "otp-verification",
  ONBOARDING: "onboarding",
  ONBOARDING_PROCESS: "onboarding-process",
  FORGET_PASS: "forgot-password",
  DASHBOARD: "dashboard",
  PAGE_NOT_FOUND: "404PageNotfound",
  PROJECTS: "projects",
  TERMS: "terms-and-conditions",
  POLICY: "refund-cancellation-policy",
  CONTACT_US: "contact-us",
  CLIENT_SIGNATURE_TEMPLATE: "edit-template",
  CLIENT_SIGNATURE_VIEW: "view-template",
};

export const EMAIL_REGEX = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
export const PASS_REGEX =
  /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;

export const industry = [
  { label: "Accounting", value: "Accounting" },
  { label: "Architecture", value: "Architecture" },
  { label: "Art", value: "Art" },
  { label: "Bakery", value: "Bakery" },
  { label: "Blogging", value: "Blogging" },
  { label: "Chartered Accountants", value: "Chartered Accountants" },
  { label: "Business Consulting", value: "Business Consulting" },
  { label: "Calligraphy", value: "Calligraphy" },
  { label: "Catering", value: "Catering" },
  { label: "Coaching", value: "Coaching" },
  { label: "Content Creation", value: "Content Creation" },
  { label: "Copywriting", value: "Copywriting" },
  { label: "Digital Marketing", value: "Digital Marketing" },
  { label: "DJ/Entertainment", value: "DJ/Entertainment" },
  { label: "Education/Coaching", value: "Education/Coaching" },
  { label: "Event Planning", value: "Event Planning" },
  { label: "Chartered Accountants", value: "Chartered Accountants" },
  { label: "Exports", value: "Exports" },
  { label: "Fashion", value: "Fashion" },
  { label: "Fitness", value: "Fitness" },
  { label: "Florist", value: "Florist" },
  { label: "Food & Drinks", value: "Food & Drinks" },
  { label: "Freelancer", value: "Freelancer" },
  { label: "Graphic Design", value: "Graphic Design" },
  { label: "Hair & Makeup", value: "Hair & Makeup" },
  { label: "Health and Fitness", value: "Health and Fitness" },
  { label: "Home Theater", value: "Home Theater" },
  { label: "Influencer", value: "Influencer" },
  { label: "Interior Design", value: "Interior Design" },
  { label: "Landscaping", value: "Landscaping" },
  { label: "Law Firm", value: "Law Firm" },
  { label: "Lighting/AV", value: "Lighting/AV" },
  { label: "Manufacturing", value: "Manufacturing" },
  { label: "Marketing Agency", value: "Marketing Agency" },
  { label: "Marketing Consulting", value: "Marketing Consulting" },
  { label: "Photography", value: "Photography" },
  { label: "Planning", value: "Planning" },
  { label: "Printing and Packaging", value: "Printing and Packaging" },
  { label: "Real Estate", value: "Real Estate" },
  { label: "Rentals", value: "Rentals" },
  { label: "Social Media", value: "Social Media" },
  { label: "Software Development", value: "Software Development" },
  { label: "Stationery", value: "Stationery" },
  { label: "Stylists", value: "Stylists" },
  { label: "Tutoring", value: "Tutoring" },
  { label: "Venue", value: "Venue" },
  { label: "Videography", value: "Videography" },
  { label: "Virtual Assistants", value: "Virtual Assistants" },
  { label: "Web Design", value: "Web Design" },
  { label: "Wedding Rentals", value: "Wedding Rentals" },
  { label: "Wedding Planners", value: "Wedding Planners" },
];

// export const role = [
//   { label: "Owner", value: "Owner" },
//   { label: "Accountant", value: "Accountant" },
//   { label: "Supervisor", value: "Supervisor" },
// ];

export const role = [
  { label: "Associate", value: "Associate" },
  { label: "Manager", value: "Manager" },
  { label: "Accountant", value: "Accountant" },
  { label: "Admin", value: "Admin" },
];

export const _role = [
  { label: "Project Manager", value: "Project Manager" },
  { label: "Supervisor", value: "Supervisor" },
  { label: "Technician", value: "Technician" },
  { label: "Partner", value: "Partner" },
  { label: "Owner", value: "Owner" },
  { label: "Manager", value: "Manager" },
  { label: "Specialist", value: "Specialist" },
  { label: "Co-ordinator", value: "Co-ordinator" },
  { label: "Marketing", value: "Marketing" },
  { label: "Accountant", value: "Accountant" },
  { label: "Sales", value: "Sales" },
  { label: "Support", value: "Support" },
  // { label: 'Viewer-Read only', value: 'Viewer-Read only' }
];

// export const projectStage = [
//     { label: 'Initial Contact', value: 'Initial Contact',color:'#307EF3' },
//     { label: 'Proposal', value: 'Proposal',color:'#75B261' },
//     { label: 'Contract', value: 'Contract' ,color:'#10B981'},
//     { label: 'Design', value: 'Design' ,color:'#FA559A'},
//     { label: 'Execution', value: 'Execution',color:'#F7980A' },
//     { label: 'Handover', value: 'Handover' ,color:'#D60C0C'},
// ]

export const projectStage = [
  { label: "Inquiry", value: "Inquiry", color: "#3498db" },
  { label: "Proposal", value: "Proposal", color: "#f1c40f" },
  { label: "Negotiation", value: "Negotiation", color: "#e67e22" },
  { label: "Contract", value: "Contract", color: "#2ecc71" },
  { label: "Planning", value: "Planning", color: "#9b59b6" },
  { label: "Execution", value: "Execution", color: "#ff8c00" },
  { label: "Delivery", value: "Delivery", color: "#1abc9c" },
  { label: "Feedback", value: "Feedback", color: "#34495e" },
];

export const projectStatus = [
  { label: "On-track", value: "On-track", color: "#2ecc71" },
  { label: "Delayed", value: "Delayed", color: "#e74c3c" },
  { label: "On-hold", value: "On-hold", color: "#f39c12" },
  { label: "Canceled", value: "Canceled", color: "#7f8c8d" },
  { label: "Complete", value: "Complete", color: "#3498db" },
  { label: "At-Risk", value: "At-Risk", color: "#e67e22" },
  { label: "Not Started", value: "Not Started", color: "#95a5a6" },
];

export const leadSource = [
  { label: "FaceBook", value: "FaceBook" },
  { label: "Youtube", value: "Youtube" },
  { label: "Linkdin", value: "Linkdin" },
  { label: "Twitter", value: "Twitter" },
];

export const leadSourceWithOthers = [
  { label: "FaceBook", value: "FaceBook" },
  { label: "Youtube", value: "Youtube" },
  { label: "Linkdin", value: "Linkdin" },
  { label: "Twitter", value: "Twitter" },
  { label: "Other", value: "Other" },
];

export const projectOverviewLeadSource = [
  { label: "Website", value: "Website" },
  { label: "Facebook", value: "Facebook" },
  { label: "Instagram", value: "Instagram" },
  { label: "LinkedIn", value: "LinkedIn" },
  { label: "Twitter", value: "Twitter" },
  { label: "Referral", value: "Referral" },
  { label: "Email Marketing", value: "Email Marketing" },
  { label: "Online Advertising", value: "Online Advertising" },
  { label: "Google Search", value: "Google Search" },
  { label: "Ads", value: "Ads" },
  { label: "Influencer", value: "Influencer" },
  // { label: 'Other', value: 'Other' }
];

export const _goals = {
  1: "Send Invoices",
  2: "Sign Contract",
  3: "Manage Project",
  4: "Change Mgmt",
  5: "Collect Payments",
  6: "File Organization",
  7: "Expense Tracking",
  8: "Proposals",
  9: "Client Portal",
  10: "Tasks",
  11: "Templates",
  12: "Client Mgmt",
  13: "Vendor Mgmt",
  14: "Reporting",
  15: "Other",
};

export const statusClass = {
  "on-track": "green_status",
  "on-hold": "hold_status",
  execution: "green_status",
  delayed: "delayed_status",
  pending: "danger_status",
  complete: "complete_status",
  "at-risk": "danger_status",
  "not started": "green_status",
  canceled: "canceled_status",
};

export const phaseClass = {
  "initial contact": "intial_phases",
  proposal: "proposal_phases",
  contract: "contract_phases",
  design: "design_phases",
  execution: "execution_phases",
  handover: "handover_phases",
};

// { label: 'Inquiry', value: 'Inquiry',color:'#3498db' },
//     { label: 'Proposal', value: 'Proposal',color:'#f1c40f' },
//     { label: 'Negotiation', value: 'Negotiation' ,color:'#e67e22'},
//     { label: 'Contract', value: 'Contract' ,color:'#2ecc71'},
//     { label: 'Planning', value: 'Planning' ,color:'#9b59b6'},
//     { label: 'Execution', value: 'Execution',color:'#ff8c00' },
//     { label: 'Delivery', value: 'Delivery' ,color:'#1abc9c'},
//     { label: 'Feedback', value: 'Feedback' ,color:'#34495e'},

export const projectOverViewTabs = [
  "Overview",
  "Dealroom",
  "Change Requests",
  "Project Management",
  "Invoices",
  "Expenses",
  "Files",
  "Notes & Tasks",
  "Project Team",
];

export const availableColors = [
  "#ff5733",
  "#8e44ad",
  "#1abc9c",
  "#f1c40f",
  "#9b59b6",
  "#16a085",
  "#2980b9",
  "#2c3e50",
  "#e84393",
  "#34495e",
];

export const _contractStatus = {
  1: { label: "DRAFT", value: "Draft", color: "#D3D3D3" },
  2: { label: "SUBMITTED", value: "Submitted", color: "#FFA500" },
  3: { label: "REJECTED", value: "Rejected", color: "#FF4500" },
  4: { label: "APPROVED", value: "Approved", color: "#1E90FF" },
  5: { label: "CANCELED", value: "Canceled", color: "#A9A9A9" },
  6: { label: "PENDING", value: "Pending", color: "#B22222" },
  7: { label: "SentForApproval", value: "Sent For Approval", color: "#007BFF" },
  8: { label: "Viewed", value: "Viewed", color: "#5BC0DE" },
  9: {
    label: "PartiallyApproved",
    value: "Partially Approved",
    color: "#FFC107",
  },
  10: { label: "Expired", value: "Expired", color: "#FD7E14" },
  11: { label: "Completed", value: "Completed", color: "#155724" },
  12: { label: "PaymentFailed", value: "Payment Failed", color: "#B22222" },
  13: { label: "Refunded", value: "Refunded", color: "#9370DB" },
  14: { label: "Overdue", value: "Overdue", color: "#FF4500" },
  15: { label: "WrittenOff", value: "Written Off", color: "#000" },
  16: { label: "MarkAsPaid", value: "Mark as Paid", color: "#00889D" },
  17: { label: "PartiallySigned", value: "Partially Signed", color: "#FFC107" },
  18: { label: "Failed", value: "Failed", color: "#721C24" },
  19: { label: "Signed", value: "Signed", color: "#28A745" },
  20: { label: "Sent", value: "Sent", color: "#007BFF" },
};

export const invoiceFilterStatus = [
   { id: 1, label: "DRAFT", value: "Draft", color: "#D3D3D3" },
   { id : 5 ,label: "CANCELED", value: "Canceled", color: "#A9A9A9" },
   { id : 8 ,label: "Viewed", value: "Viewed", color: "#5BC0DE" },
   { id : 16 ,label: "MarkAsPaid", value: "Paid", color: "#00889D" },
   { id : 12 ,label: "PaymentFailed", value: "Payment Failed", color: "#B22222" },
   { id : 13 ,label: "Refunded", value: "Refunded", color: "#9370DB" },
   { id : 14 ,label: "Overdue", value: "Overdue", color: "#FF4500" },
   { id : 15 ,label: "WrittenOff", value: "Written Off", color: "#000" },
   { id : 17 ,label: "PartiallyPaid", value: "Partially Paid", color: "#FFC107" },
   { id : 20,label: "Sent", value: "Sent", color: "#007BFF" },
]



export const contractStatus = [
  { id: 1, label: "DRAFT", value: "Draft", color: "#D3D3D3" },
  { id: 2, label: "SUBMITTED", value: "Submitted", color: "#FFA500" },
  { id: 3, label: "REJECTED", value: "Rejected", color: "#FF4500" },
  { id: 4, label: "APPROVED", value: "Approved", color: "#1E90FF" },
  { id: 5, label: "CANCELED", value: "Canceled", color: "#A9A9A9" },
  { id: 6, label: "PENDING", value: "Pending", color: "#B22222" },
  {
    id: 7,
    label: "SentForApproval",
    value: "Sent For Approval",
    color: "#007BFF",
  },
  { id: 8, label: "Viewed", value: "Viewed", color: "#5BC0DE" },
  {
    id: 9,
    label: "PartiallyApproved",
    value: "Partially Approved",
    color: "#FFC107",
  },
  { id: 10, label: "Expired", value: "Expired", color: "#FD7E14" },
  { id: 11, label: "Completed", value: "Completed", color: "#155724" },
  {
    id: 12,
    label: "PaymentFailed",
    value: "Payment Failed",
    color: "#B22222",
  },
  { id: 13, label: "Refunded", value: "Refunded", color: "#9370DB" },
  { id: 14, label: "Overdue", value: "Overdue", color: "#FF4500" },
  { id: 15, label: "WrittenOff", value: "Written Off", color: "#000" },
  { id: 16, label: "MarkAsPaid", value: "Mark as Paid", color: "#00889D" },
  {
    id: 17,
    label: "PartiallySigned",
    value: "Partially Signed",
    color: "#FFC107",
  },
  { id: 18, label: "Failed", value: "Failed", color: "#721C24" },
  { id: 19, label: "Signed", value: "Signed", color: "#28A745" },
];



export const contractStatusForDealroom = [
  { id: 1, label: "DRAFT", value: "Draft", color: "#D3D3D3" },
  { id: 20, label: "Sent", value: "Sent", color: "#007BFF" },
  { id: 8, label: "Viewed", value: "Viewed", color: "#5BC0DE" },
  { id: 19, label: "Signed", value: "Signed", color: "#28A745" },
  { id: 17, label: "PartiallySigned", value: "Partially Signed", color: "#FFC107"},
  { id: 21, label: "Canceled", value: "Canceled", color: "#A9A9A9"},
  { id: 18, label: "Failed", value: "Failed", color: "#721C24" },
  { id: 10, label: "Expired", value: "Expired", color: "#FD7E14" },
  { id: 11, label: "Completed", value: "Completed", color: "#155724" },
];

export const magicFields = {
  clientFirstName: 1,
  clientLastName: 2,
  clientAddress: 3,
  clientEmail: 4,
  clientInitials: 5,
  clientSignature: 6,
  companyName: 7,
  companyAddress: 8,
  companyPhoneNumber: 9,
  projectName: 10,
  projectAddress: 11,
  projectDueDate: 12,
};

export const PDF_FIELD_TYPE = {
  SIGNER: 1,
  CLIENT: 2,
  PROJECT: 3,
  COMPANY: 4,
  PAYMENT: 5,
  SESSION: 6,
};

export const PDF_VALUE_TYPE = {
  SIGNER: 1, // Add Box
  INITIAL: 2, // Add Box
  TEXT: 3, // Add Box
  CALENDAR: 4,
  CHECKED: 5, // Add Box
  NAME: 6,
  CALL: 7,
  DATE_SIGN: 8,
  SHOW_BOX: 9, // Add Box
  EMAIL: 10,
};

export const ACTION_TYPE = {
  DELETE: 1,
  CANCEL: 2,
  DOWNLOAD: 3,
};

export const priorityStatus = [
  { label: "Urgent", value: "Urgent", color: "#ff0000" },
  { label: "High", value: "High", color: "#ffa500" },
  { label: "Medium", value: "Medium", color: "#ffd700" },
  { label: "Low", value: "Low", color: "#28a745" },
  { label: "Deferred", value: "Deferred", color: "#808080" },
];

export const completedStatus = [
  { label: "0%", value: 0, progress: 0 },
  { label: "10%", value: 10, progress: 0.1 },
  { label: "20%", value: 20, progress: 0.2 },
  { label: "30%", value: 30, progress: 0.3 },
  { label: "40%", value: 40, progress: 0.4 },
  { label: "50%", value: 50, progress: 0.5 },
  { label: "60%", value: 60, progress: 0.6 },
  { label: "70%", value: 70, progress: 0.7 },
  { label: "80%", value: 80, progress: 0.8 },
  { label: "90%", value: 90, progress: 0.9 },
  { label: "100%", value: 100, progress: 1 },
];

export const sortingDates = [
  { id: 1, label: "Ascending", value: "ASC", isChecked: false },
  { id: 2, label: "Descending", value: "DESC", isChecked: false },
];
