import React, { useState, useEffect, useRef, useCallback } from "react";
import DatePicker from "react-datepicker";
import CommonButton from "../../../components/common/CommonButton";
import CustomSelect from "../../../components/common/CustomSelect";
import CustomToggle from "../../../components/common/CustomToggle";
import DeleteIcon from "./../../../assets/dashboard/delete.svg";
import "./invoice.css"
import useToast from "../../../utils/customHooks/useToast";
import PreviewInvoice from "./previewInvoice";
import plusBlueIcon from "../../../assets/dashboard/plus_blue.svg";
import DescriptionDropdown from "./descriptionDropdown";
import { FaChevronDown, FaChevronUp } from 'react-icons/fa'; // Import arrow icons
import InvoiceAmountDisplay from "./invoiceAmount";
import { useLocation } from "react-router-dom";


export default function InvoiceMain({ activeTab, onCancel }) {
  const showToast = useToast();
  const location = useLocation();
  const { id } = location.state || {};
  
  const dates = Array.from({ length: 30 }, (_, i) => i + 1);
  const options = [
    { label: "Day", value: "1" },
    { label: "Week", value: "2" },
    { label: "Month", value: "3" },
    { label: "Year", value: "4" },
  ];

  const discountOptions = [
    { label: '₹', value: '₹' },
    { label: "%", value: "%" }
  ]
  const ProjectDetails = JSON.parse(localStorage.getItem('projectDetail'))  
  

  const [invoiceNumber, setInvoiceNumber] = useState('');
  const [invoiceError, setInvoiceError] = useState(false);
  const [StartDateError, setStartDateError] = useState("")
  const [endDateError, setendDateError] = useState("")
  
  const now = new Date();
  useEffect(() => {
    const newInvoiceNumber = [
      String(now.getMonth() + 1).padStart(2, '0'),
      String(now.getDate()).padStart(2, '0'),
      String(now.getHours()).padStart(2, '0'),
      String(now.getMinutes()).padStart(2, '0'),
      String(now.getSeconds()).padStart(2, '0')
    ].join('');

    setInvoiceNumber(newInvoiceNumber);
    setFormValue(prev => ({
      ...prev,
      invoiceHash: newInvoiceNumber
    }));
  }, []);

  const [formValue, setFormValue] = useState({
    recurrenceData: {
      isRecurrence: false,
      sendDay: 0,
      sendPeriod: 0,
      startDate: new Date(),
      endDate: new Date(),
      isNeverEnd: false
    },
    invoiceItems: [
      { item: "", description: "", rate: "", quantity: "", totalCost: "" }
    ],
    invoiceAmount: {
      subTotalAmount: "",
      discountData: [
        { name: "", value: "", type: { label: "", value: "" }, discountedValue:"" }
      ],
      taxData: [
        { name: "", value: "", type: { label: "", value: "" }, taxedValue:"" }
      ],
      totalAmount: ""
    },
    depositSchedule: {
      initialType: 0,
      initialValue: 0,
      initialAmount: 0,
      initialDueDate: "",
      balanceValue: 0,
      balanceAmount: 0,
      balanceDueDate: ""
    },
    balanceSchedule: {
      initialType: 0,
      initialValue: 0,
      initialAmount: 0,
      initialDueDate: "",
      balanceValue: 0,
      balanceAmount: 0,
      balanceDueDate: ""
    },
    createBy: "",
    status: 1,
    projectName: ProjectDetails?.projectName,
    title: "",
    dueDate: new Date(),
    invoiceHash: invoiceNumber,
    notes: "",
    invoiceType: 0,
    projectId: 0,
    additionalNote:"",
  });
  const [errors, setErrors] = useState({});

  const validateForm = () => {
    const newErrors = {};

    if (!formValue.title) newErrors.title = "";
    if (!formValue.projectName) newErrors.projectName = "Project name is required.";
    // if (!formValue.status) newErrors.status = "Status is required.";
    if (!formValue.invoiceHash) newErrors.invoiceHash = "Invoice number is required.";
    
    if (enableRecurring === true){      
      // if (!formValue.recurrenceData?.sendDay) newErrors.sendDay = "Sendday is required.";
      if (!formValue.recurrenceData?.startDate){
        setStartDateError("StartDate is required.")
      }else{
        setStartDateError("")
      }    

      if (!formValue.recurrenceData?.startDate) newErrors.startDate = "startDate is required.";

      if (!formValue.recurrenceData?.endDate){
        setendDateError("EndDate is required.")
      }else{
        setendDateError("")
      }
      // if (!formValue.recurrenceData?.endDate) newErrors.endDate = "endDate is required.";
    }


    if (formValue.dueDate === "") {
      newErrors.dueDate = "Due date is required.";
    }

    if (Array.isArray(formValue.invoiceItems)) {
      formValue.invoiceItems.forEach((item, index) => {
          if (!item.item) newErrors[`item_${index}`] = "Item is required.";
          if (!item.description) newErrors[`description_${index}`] = "Description is required.";
          
          const numericRate = parseFloat(item.rate);
          if (isNaN(numericRate) || numericRate <= 0) newErrors[`rate_${index}`] = "Rate required.";
          
          const numericQuantity = parseFloat(item.quantity);
          if (isNaN(numericQuantity) || numericQuantity <= 0) newErrors[`quantity_${index}`] = "Quantity required.";
      });
  } else {
      newErrors.invoiceItems = "Invoice items list is required.";
  }

    formValue.invoiceAmount.discountData.forEach((discount, index) => {
      if (discount.type && discount.type.value === "%" && (parseFloat(discount.value) < 0 || parseFloat(discount.value) > 100)) {
        newErrors[`discount_${index}`] = "Discount percentage must be between 0 and 100.";
      }
    });

    formValue.invoiceAmount.taxData.forEach((tax, index) => {
      if (tax.type && tax.type.value === "%" && (parseFloat(tax.value) < 0 || parseFloat(tax.value) > 100)) {
        newErrors[`tax_${index}`] = "Tax percentage must be between 0 and 100.";
      }
    });

    setErrors(newErrors);    
    return Object.keys(newErrors).length === 0;
  };

  const validatePeriod = () => {
      let hasErrors = false;
      
      if (formValue.recurrenceData?.sendDay <= 0 || formValue.recurrenceData?.sendDay === undefined) {
          showToast("Send day cannot be empty or less than 1.", "error");
          hasErrors = true;
      }else if (formValue.recurrenceData?.sendPeriod <= 0 || formValue.recurrenceData?.sendPeriod === undefined) {
          showToast("Send period cannot be empty or less than 1.", "error");
          hasErrors = true;
      }
      
      return !hasErrors; // Return true if no errors, false if errors exist
  };
  const [OpenPreview, setOpenPreview] = useState(false)
  const handleOpenPreview = () => {
    if (!validateForm()) {
      showToast("Please correct the errors before previewing.", "error");
      return;
    }    

    
    if (enableRecurring === true){
      const isPeriodValid = validatePeriod();
        if (!isPeriodValid) {
          return;
      }
    }
    if (invoiceError === true){
      showToast("Please correct the errors before previewing.", "error");
      return
    }
    
    if(formValue.invoiceItems.length <= 0){
      showToast("Item fields can not be null.", "error")
      return
    }
    
    setOpenPreview(true)
    const previewData = { ...formValue };
    if (enableRecurring === false) {
      delete previewData.recurrenceData;
    }
    // navigate("/preview", { state: { formValue: previewData } });

    // 
  }; 


  const handleAddItem = () => {
    setFormValue(prev => ({
      ...prev,
      invoiceItems: [
        ...prev.invoiceItems,
        { description: "", rate: "", quantity: "", totalCost: "" }
      ]
    }));
  };

  const handleDeleteItem = (index) => {    
    setFormValue(prev => ({
      ...prev,
      invoiceItems: prev.invoiceItems.filter((_, i) => i !== index)
    }));
  };

  const handleItemChange = (index, field, value) => {
    const parsedValue = parseFloat(value);
  
    setFormValue(prev => {
      const updatedItems = [...prev.invoiceItems];
      updatedItems[index] = {
        ...updatedItems[index],
        [field]: isNaN(parsedValue) ? value : parsedValue,
      };
  
      // Recalculate total cost
      updatedItems[index].totalCost = (updatedItems[index].rate || 0) * (updatedItems[index].quantity || 0);
  
      return {
        ...prev,
        invoiceItems: updatedItems
      };
    });
  
    // Error validation for each field
    if (field === "item") {
      setErrors(prevErrors => ({
        ...prevErrors,
        [`item_${index}`]: value === "" ? "Item is required." : ""
      }));
    } else if (field === "rate") {
      setErrors(prevErrors => ({
        ...prevErrors,
        [`rate_${index}`]: isNaN(parsedValue) || parsedValue <= 0 ? "Rate required." : ""
      }));
    } else if (field === "quantity") {
      setErrors(prevErrors => ({
        ...prevErrors,
        [`quantity_${index}`]: isNaN(parsedValue) || parsedValue <= 0 ? "Quantity required." : ""
      }));
    }
  };
  

  function handleChange(e, index) {
    let value = e.target.value;
    
    if (value && value.length > 9) {
      value = 0;
    }
    
    handleItemChange(index, 'rate', parseFloat(value));
  }

  function handleQuantityChange(e, index) {
    let value = parseFloat(e.target.value);
    
    if (value > 999) {
      value = 0;
    }
    
    handleItemChange(index, 'quantity', value);
  }

  const handleAddDiscount = () => {
    setFormValue(prev => ({
      ...prev,
      invoiceAmount: {
        ...prev.invoiceAmount,
        discountData: [
          ...prev.invoiceAmount.discountData,
          { name: "", value: "", type: { label: "", value: "" } } 
        ]
      }
    }));
  };

  const handleDeleteDiscount = (index) => {
    setFormValue(prev => ({
      ...prev,
      invoiceAmount: {
        ...prev.invoiceAmount,
        discountData: prev.invoiceAmount.discountData.filter((_, i) => i !== index)
      }
    }));
  };

  const handleDiscountChange = (index, field, value) => {

    setFormValue(prev => {
      const updatedDiscounts = [...prev.invoiceAmount.discountData];
  
      if (field === 'type') {
        updatedDiscounts[index] = { ...updatedDiscounts[index], type: value, value: "" };
      } else {
        updatedDiscounts[index] = { ...updatedDiscounts[index], [field]: value };
      }
  
      return {
        ...prev,
        invoiceAmount: {
          ...prev.invoiceAmount,
          discountData: updatedDiscounts
        }
      };
    });
  };
  
  const handleDiscountChangeValue = (index, field, value) => {
    const parsedValue = parseFloat(value);
    const isPercentage = formValue.invoiceAmount.discountData[index]?.type?.value === '%';
    
    if (isPercentage) {
      if (parsedValue > 100) {
        return;
      }
    } else {
      const subTotal = formValue.invoiceItems.reduce((sum, item) => sum + item.totalCost, 0);
      if (parsedValue > subTotal) {
        return;
      }
    }
    
    const updatedDiscounts = formValue.invoiceAmount.discountData.map((discount, idx) => 
      idx === index ? { ...discount, [field]: value } : discount
    );
    setFormValue(prev => ({
      ...prev,
      invoiceAmount: {
        ...prev.invoiceAmount,
        discountData: updatedDiscounts
      }
    }));
  };
  
  const handleTaxChangeValue = (index, field, value) => {
    const parsedValue = parseFloat(value);
    const isPercentage = formValue.invoiceAmount.taxData[index]?.type?.value === '%';
    
    if (isPercentage) {
      if (parsedValue > 100) {
        return;
      }
    } else {
      const subTotal = formValue.invoiceItems.reduce((sum, item) => sum + item.totalCost, 0);
      if (parsedValue > subTotal) {
        return;
      }
    }
    
    const updatedTaxes = formValue.invoiceAmount.taxData.map((tax, idx) =>
      idx === index ? { ...tax, [field]: value } : tax
    );
    setFormValue(prev => ({
      ...prev,
      invoiceAmount: {
        ...prev.invoiceAmount,
        taxData: updatedTaxes
      }
    }));
  };
  

  const handleAddTax = () => {
    setFormValue(prev => ({
      ...prev,
      invoiceAmount: {
        ...prev.invoiceAmount,
        taxData: [
          ...prev.invoiceAmount.taxData,
          { name: "", value: "", type: { label: "", value: "" } } 
        ]
      }
    }));
  };

  const handleDeleteTax = (index) => {
    setFormValue(prev => ({
      ...prev,
      invoiceAmount: {
        ...prev.invoiceAmount,
        taxData: prev.invoiceAmount.taxData.filter((_, i) => i !== index)
      }
    }));
  };

  const handleTaxChange = (index, field, value) => {
    setFormValue(prev => {
      const updatedTaxes = [...prev.invoiceAmount.taxData];
  
      if (field === 'type') {
        updatedTaxes[index] = { ...updatedTaxes[index], type: value, value: "" };
      } else {
        updatedTaxes[index] = { ...updatedTaxes[index], [field]: value };
      }
  
      return {
        ...prev,
        invoiceAmount: {
          ...prev.invoiceAmount,
          taxData: updatedTaxes
        }
      };
    });
  };


  const calculateTotals = () => {
    const subTotal = formValue.invoiceItems.reduce((sum, item) => sum + (item.totalCost || 0), 0);
  
    const totalDiscount = formValue.invoiceAmount.discountData.reduce((sum, discount, index) => {
      let discountValue = 0;
      if (discount.type && discount.type.value === "%") {
        discountValue = parseFloat(discount.value) || 0;
        if (!isNaN(discountValue)) {
          discountValue = subTotal * (discountValue / 100);
        }
      } else if (discount.type && discount.type.value === '₹') {
        discountValue = parseFloat(discount.value) || 0;
      }
  
      formValue.invoiceAmount.discountData[index].discountedValue = discountValue;
  
      return sum + discountValue;
    }, 0);
  
    const discountedPrice = subTotal - totalDiscount;
  
    const totalTax = formValue.invoiceAmount.taxData.reduce((sum, tax, index) => {
      let taxValue = 0;
      if (tax.type && tax.type.value === "%") {
        taxValue = parseFloat(tax.value) || 0;
        if (!isNaN(taxValue)) {
          taxValue = discountedPrice * (taxValue / 100);
        }
      } else if (tax.type && tax.type.value === '₹') {
        taxValue = parseFloat(tax.value) || 0;
      }
  
      // Update the taxed value in taxData
      formValue.invoiceAmount.taxData[index].taxedValue = taxValue;
  
      return sum + taxValue;
    }, 0);
  
    const total = discountedPrice + totalTax;
  
    setFormValue(prev => ({
      ...prev,
      invoiceAmount: {
        ...prev.invoiceAmount,
        subTotalAmount: subTotal,
        totalAmount: total,
        discountData: formValue.invoiceAmount.discountData,
        taxData: formValue.invoiceAmount.taxData
      }
    }));
  };
  

  useEffect(() => {
    calculateTotals();
  }, [formValue.invoiceItems, formValue.invoiceAmount.discountData, formValue.invoiceAmount.taxData]);
  

  const handleSelect = (value, name) => {       
    setFormValue(prevState => ({
      ...prevState,
      recurrenceData: {
        ...prevState.recurrenceData,
        [name]: value ? value : '1' // Convert value to integer if needed
      }
    }));
  };
  const updateRecurrenceData = (key, value) => {
    setFormValue(prev => {
        const updatedRecurrenceData = {
            ...prev.recurrenceData,
            [key]: value
        };
        const startDate = updatedRecurrenceData.startDate;
        const endDate = updatedRecurrenceData.endDate;

        if (startDate && endDate && new Date(endDate) < new Date(startDate)) {
            showToast("End date cannot be earlier than start date", "error");
            return prev; 
        }

        return {
            ...prev,
            recurrenceData: updatedRecurrenceData
        };
    });
  };



  const handleDateChange = (date, name) => {
    
    if (name === "startDate"){
      if (date === null){
        setStartDateError("StartDate is required.")
      }else{
        setStartDateError("")
      }
    }else if(name === "endDate"){
      if (date === null){
        setendDateError("EndDate is required.")
      }else{
        setendDateError("")
      }
    }

    if (name == "startDate" || name == "endDate"){
      const [key] = name.split('.');
      updateRecurrenceData(key, date ? date.toISOString().split('T')[0] : "");
    }

    setFormValue(prev => ({
      ...prev,
      [name]: date ? date.toISOString().split('T')[0] : ""
    }));
      
  };

  const [enableRecurring, setenableRecurring] = useState(false)
  const handleRecurrenceToggle = (e) => {
    const isChecked = e.target.checked;
    
    setenableRecurring(isChecked);
  
    if (!isChecked) {
      setFormValue((prevFormValue) => ({
        ...prevFormValue,
        recurrenceData: {}
      }));
    }
  };  

  useEffect(()=>{
      if (enableRecurring === false){
        setFormValue((prevFormValue) => ({
          ...prevFormValue,
          recurrenceData: {}
        }));
      }
  },[enableRecurring])


  const handleInputChange = (index, field, value) => {
    setFormValue(prev => {
      const updatedItems = [...prev.invoiceItems];
      updatedItems[index] = {
        ...updatedItems[index],
        [field]: value
      };
      return {
        ...prev,
        invoiceItems: updatedItems
      };
    });
  
    // Update description error
    if (field === "description") {
      setErrors(prevErrors => ({
        ...prevErrors,
        [`description_${index}`]: value === "" ? "Description is required." : ""
      }));
    }
  };
  
  

  const dropdownRef = useRef({});

  // Function to toggle dropdown visibility
  const [dropdownVisibility, setDropdownVisibility] = useState({});

  const toggleDropdown = (index) => {
    setDropdownVisibility(prevState => ({
      ...prevState,
      [index]: !prevState[index]
    }));
  };

  // Function to handle clicks outside of the dropdown
  const handleClickOutside = (event) => {
    // Check if dropdownRefs.current is defined and is an object
    if (dropdownRef.current && typeof dropdownRef.current === 'object') {
      Object.keys(dropdownRef.current).forEach((key) => {
        if (dropdownRef.current[key] && !dropdownRef.current[key].contains(event.target)) {
          setDropdownVisibility(prevState => ({
            ...prevState,
            [key]: false
          }));
        }
      });
    }
  };
  // Effect to add and clean up event listener
  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);
  

  const handleCancel = () => {    
    setOpenPreview(false);
  };

  const preventScroll = useCallback((e) => {
    e.preventDefault();
  }, []);
  
  const handleErrorChange = (hasError) => {
    setInvoiceError(hasError);    
  };

 
  return (
    <div>
      { OpenPreview ? (
        <PreviewInvoice onCancel={handleCancel} invoiceMainClose={onCancel} formValue={formValue} invoiceNumber={invoiceNumber} enableRecurring={enableRecurring} ProjectDetails={ProjectDetails} />
      ) : (
        <>
          <div>

            <CustomToggle
              label="Recurring Invoice :"
              name="recurrenceData.isRecurrence"
              onChange={handleRecurrenceToggle}
            />
            <div className="_flex_row_spacebtw_ invoice_main">
              <div className="invoice_main_sub">
                <p>Send Every</p>
                <div className="dflex">
                <div className="select-container">
                    <CustomSelect
                      options={dates.map((date) => ({ value: date, label: date }))}
                      placeholder="1"
                      onSelect={(value) => handleSelect(value?.value, 'sendDay')}
                      className="dateselect"
                      disabled={!enableRecurring}
                    />
                    {/* {errors.sendDay && <span className="error">{errors.sendDay}</span>} */}
                  </div>
                  <div className="select-container">
                    <CustomSelect
                      options={options}
                      placeholder="Month"
                      onSelect={(value) => handleSelect(value?.value, 'sendPeriod')}
                      className="monthselect"
                      disabled={!enableRecurring}
                    />
                    {/* {errors.sendPeriod && <span className="error">{errors.sendPeriod}</span>} */}
                  </div>
                </div>
              </div>
              <div className="invoice_main_sub">
                <p>Starts On</p>
                <div className="modal_main_li">
                  <DatePicker
                    selected={formValue?.recurrenceData?.startDate ? new Date(formValue?.recurrenceData?.startDate) : null}
                    placeholderText="MMMM DD, YYYY"
                    dateFormat="MMMM dd, yyyy"
                    onChange={(date) => handleDateChange(date, 'startDate')}
                    disabled={enableRecurring === false}
                    minDate={now}
                  />
                </div>
                {StartDateError && <span className="error">{StartDateError}</span>}
              </div>
              <div className="invoice_main_sub">
                <div className="_flex_row_spacebtw_">
                  <p>Ends On</p>
                  <p style={{ gap: "10px" }} className="dflex">
                    <input
                      type="checkbox"
                      checked={formValue?.recurrenceData?.isNeverEnd}
                      onChange={() => setFormValue(prev => ({
                        ...prev,
                        recurrenceData: {
                          ...prev.recurrenceData,
                          isNeverEnd: !prev.recurrenceData.isNeverEnd
                        }
                      }))}
                      disabled={enableRecurring === false}
                    />
                    Never Ends
                  </p>
                </div>
                <div className="modal_main_li">
                  <DatePicker
                    selected={formValue?.recurrenceData?.endDate ? new Date(formValue.recurrenceData?.endDate) : null}
                    placeholderText="MMMM DD, YYYY"
                    dateFormat="MMMM dd, yyyy"
                    onChange={(date) => handleDateChange(date, 'endDate')}
                    disabled={formValue?.recurrenceData?.isNeverEnd || !enableRecurring}
                    minDate={now}
                  />
                </div>
                {endDateError && <span className="error">{endDateError}</span>}
              </div>
            </div>
            <div className="invoice_main_list">
              <ul className="modal_main_ul">
                <li className="modal_main_li">
                  <label className="font_14">Title </label>
                  <div className="_editable_">
                    <input
                      type="text"
                      placeholder="Title"
                      value={formValue?.title}
                      onChange={(e) => {setFormValue(prev => ({ ...prev, title: e.target.value}));
                        
                     }}
                    />
                  </div>
                  {errors.title && <span className="error">{errors.title}</span>}
                </li>
                <li className="modal_main_li">
                  <label className="font_14">Project</label>
                  <div className="_editable_">
                    <input
                      type="text"
                      placeholder="Project Name"
                      value={ProjectDetails?.projectName}
                      readOnly
                      onChange={(e) => {setFormValue(prev => ({ ...prev, projectName: e.target.value }));
                    }}
                    />
                  </div>
                  {errors.projectName && <span className="error">{errors.projectName}</span>}
                </li>
                {/* <li className="modal_main_li">
                  <label className="font_14">Status</label>
                  <CustomSelect
                    options={contractStatus.map(option => ({ ...option, value: parseInt(option.value, 10) }))}
                    name="status"
                    placeholder="Status"
                    onSelect={(value) => {handleSelect(value, 'status');
                      validateForm()
                    }}
                  />
                {errors.status && <span className="error">{errors.status}</span>}
                </li> */}
                <li className="modal_main_li">
                  <label className="font_14">Invoice Number</label>
                  <input
                    type="number"
                    min={0}
                    placeholder="Invoice Number"
                    value={invoiceNumber}
                    readOnly
                    onChange={(e) => setFormValue(prev => ({ ...prev, invoiceHash: e.target.value }))}
                  />
                  {errors.invoiceHash && <span className="error">{errors.invoiceHash}</span>}
                </li>
                <li className="modal_main_li">
                  <label className="font_14">Due Date</label>
                  <DatePicker
                    selected={formValue.dueDate}
                    placeholderText="MMMM DD, YYYY"
                    dateFormat="MMMM dd, yyyy"
                    onChange={(date) => {handleDateChange(date, 'dueDate');
                    }}
                    minDate={now}
                  />
                {errors.dueDate && <span className="error">{errors.dueDate}</span>}
                </li>
              </ul>
            </div>
            <h2 className="dashboard_heading">Items</h2>
            <div style={{ maxHeight: "450px", overflowY: "auto", margin: "30px 0 50px" }}>
              <table className="project_table dealroom_table">
                <thead>
                  <tr>
                    <th>S.No.</th>
                    <th>Item</th>
                    <th>Item Description</th>
                    <th>Rate</th>
                    <th>Quantity</th>
                    <th>Total Cost</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  {formValue.invoiceItems.map((item, index) => (
                    <tr key={index} ref={el => (dropdownRef.current[index] = el)}>
                      <td>{index + 1}</td>
                      <td>
                        <li className="modal_main_li">
                          <div className="_editable_">
                            <input
                              type="text"
                              placeholder="Item Name"
                              value={item.item}
                              onChange={(e) => {handleItemChange(index, 'item', e.target.value);
                              }}
                              maxLength={30}
                            />
                          </div>
                            {errors[`item_${index}`] && (
                              <p className="error_para"><span className="error">{errors[`item_${index}`]}</span></p>
                            )}
                        </li>
                      </td>
                      <td>
                        <li className="modal_main_li">
                        <div className="_editable_">
                          <div className="_dropdown_wrapper_ resources_dropdown">
                          <div key={index} className="_dropdown_ ">
                            <input
                              type="text"
                              placeholder="Description"
                              value={item.description}
                              onChange={(e) => handleInputChange(index, 'description', e.target.value)}
                            />
                            <button
                              className="dropdown-toggle"
                              onClick={() => toggleDropdown(index)}
                            >
                              {dropdownVisibility[index] ? <FaChevronUp className="arrow-icon" /> : <FaChevronDown className="arrow-icon" />}
                            </button>
                            <DescriptionDropdown
                              value={item.description}
                              placeholder="Type something..."
                              index={index}
                              visible={dropdownVisibility[index] && item?.description}
                            />
                          </div>
                            </div>
                        </div>
                        {errors[`description_${index}`] && (
                          <p className="error_para"><span className="error">{errors[`description_${index}`]}</span></p>
                        )}
                        </li>
                         
                      </td>
                      <td>
                      <li className="modal_main_li">
                        <div className="_editable_">
                          <input
                            type="number"
                            value={item?.rate}
                            placeholder="Rate"
                            // onChange={(e) => {handleItemChange(index, 'rate', parseFloat(e.target.value));
                            //   validateForm()
                            // }}
                            min={0}
                            onFocus={(e) => e.target.addEventListener('wheel', preventScroll, { passive: false })}
                            onBlur={(e) => e.target.removeEventListener('wheel', preventScroll)}
                            onChange={(e) => handleChange(e, index)}
                            />
                        </div>
                        {errors[`rate_${index}`] && (
                          <p className="error_para"><span className="error">{errors[`rate_${index}`]}</span></p>
                        )}
                      </li>
                        
                      </td>
                      <td>
                      <li className="modal_main_li">
                      <div className="_editable_">
                        <input
                          type="number"
                          value={item.quantity}
                          placeholder="Quantity"
                          onFocus={(e) => e.target.addEventListener('wheel', preventScroll, { passive: false })}
                          onBlur={(e) => e.target.removeEventListener('wheel', preventScroll)}
                          onChange={(e) => {handleQuantityChange(e, index);
                          }}
                          min={0}
                          // onChange={(e) => {handleItemChange(index, 'quantity', parseFloat(e.target.value));
                          //   
                          // }}
                        />
                      </div>
                      {errors[`quantity_${index}`] && (
                        <p className="error_para"><span className="error">{errors[`quantity_${index}`]}</span></p>
                      )}
                      </li>
                      </td>
                      <td>
                      <li className="modal_main_li">
                        <div className="_editable_">
                        {item.totalCost}
                        </div>
                      </li>
                      </td>
                      <td>
                      <li className="modal_main_li">
                      <div className="_editable_" style={{width: '22px', height: '24px'}}>
                        <img
                          src={DeleteIcon}
                          alt="Delete"
                          onClick={() => handleDeleteItem(index)}
                          />
                      </div>
                      </li>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
              {/* <CommonButton
                label="Add Item"
                fontSize="14px"
                fontWeight="700"
                className="dash_common_btn"
                icon={plusIcon}
                // isLoad={isLoading}
                onClick={handleAddItem}
              /> */}
              <span
                  style={{ cursor: "pointer" }}
                  onClick={handleAddItem}
                >
                  <img
                    src={plusBlueIcon}
                    alt="blue"
                    style={{ marginTop: -3, marginRight: 10 }}
                  />
                  Add Item
                </span>
            </div>

            <div className="invoice_main_lower dflex">

              <div className="invoice_main_lower_left">
                <p className="font_14">Additional Note</p>
                <textarea
                  style={{outline: 'none', border: '2px solid #eeeeee'}}
                  className="invoice_main_lower_left_main"
                  placeholder="Add a note that your clients can see when viewing the invoice."
                  value={formValue.additionalNote}
                  onChange={(e) => setFormValue(prev => ({...prev, additionalNote :e.target.value}))}
                  maxLength={500}
                />
              </div>

              <div className="invoice_main_lower_right">
                <div className="invoice_total_main _flex_row_spacebtw_ ">
                  <div className="invoice_total_main_left">
                    <h2 className="dashboard_heading" >
                      Sub Total
                    </h2>
                  </div>
                  <div className="invoice_total_main_right modal_main_li">
                    {/* <input
                      type="text"
                      placeholder="Rs. 25000"
                      value={'₹ '+formValue.invoiceAmount.subTotalAmount}
                      readOnly
                    /> */}
                    <InvoiceAmountDisplay formValue={formValue} onErrorChange={handleErrorChange} />
                  </div>  
                </div> 
                <div className="invoice_main_discount_tax">
                {/* <h2 className="dashboard_heading">Discounts</h2> */}
                  {/* <div className=""> */}
                    {formValue?.invoiceAmount?.discountData.map((discount, index) => (
                      <div key={index} className="invoice_total_main _flex_row_spacebtw_">
                        <div className="invoice_total_main_left invoice_total_main_left1 _flex_row_spacebtw_">
                        <h2 className="invoice_total_main_left_heading" style={{ fontWeight: "400", fontSize: '18px' }}>Discount (-)</h2>
                        <li className="modal_main_li invoice_total_dropdown1">
                          <div className="_editable_">
                            <input
                              type="text"
                              placeholder="Discount Name"
                              value={discount.name}
                              onChange={(e) => handleDiscountChange(index, 'name', e.target.value)}
                              maxLength={30}
                              />
                          </div>
                        </li>
                        <li className="modal_main_li invoice_total_dropdown2">
                          <div className="_editable_">
                            <input
                              type="number"
                              placeholder="Value"
                              value={discount?.value}
                              onFocus={(e) => e.target.addEventListener('wheel', preventScroll, { passive: false })}
                              onBlur={(e) => e.target.removeEventListener('wheel', preventScroll)}
                              min={0}
                              onChange={(e) => handleDiscountChangeValue(index, 'value', e.target.value)}
                              />
                          </div>
                          {console.log(discount?.type?.label, 'discountdiscountdiscount')}
                       
                              {errors[`discount_${index}`] && (
                                <span className="error">{errors[`discount_${index}`]}</span>
                              )}
                        </li>
                        <li className="modal_main_li invoice_total_dropdown3">
                          <div className="_editable_">
                            <CustomSelect
                              options={discountOptions}
                              placeholder="Type"
                              value={discount?.type }
                              onSelect={(value) => handleDiscountChange(index, 'type', value)}
                            />
                          </div>
                        </li>
                          </div>
                        <div className="invoice_total_main_right modal_main_li invoice_total_main_right2">
                          <input
                            type="text"
                            placeholder="Rs. 25000"
                            value={'₹ '+discount?.discountedValue}
                            readOnly
                          />
                          <div className="_editable_">
                            <img
                              src={DeleteIcon}
                              alt="Delete"
                              onClick={() => handleDeleteDiscount(index)}
                              />
                          </div>
                      
                      </div>
                       
                      </div>
                    ))}
                  {/* </div> */}
                  {/* <CommonButton
                    label="Add Discount"
                    fontSize="14px"
                    fontWeight="700"
                    className="dash_common_btn"
                    icon={plusIcon}
                    // isLoad={isLoading}
                    onClick={handleAddDiscount}
                  /> */}
                  <span
                  style={{ cursor: "pointer" }}
                  onClick={handleAddDiscount}
                >
                  <img
                    src={plusBlueIcon}
                    alt="blue"
                    style={{ marginTop: -3, marginRight: 10 }}
                  />
                  Add Discount
                </span>
                  <h2 className="dashboard_heading"></h2>
                  <div>
                    {formValue.invoiceAmount.taxData.map((tax, index) => (
                      <div key={index} className="invoice_total_main _flex_row_spacebtw_">
                        <div className="invoice_total_main_left invoice_total_main_left1 _flex_row_spacebtw_">
                        <h2 className="invoice_total_main_left_heading" style={{ fontWeight: "400", fontSize: '18px' }}>Tax</h2>
                        <li className="modal_main_li invoice_total_dropdown1">
                        <div className="_editable_">
                          <input
                            type="text"
                            placeholder="Tax Name"
                            value={tax.name}
                            onChange={(e) => handleTaxChange(index, 'name', e.target.value)}
                            maxLength={30}
                            />
                          </div>
                          </li>
                          <li className="modal_main_li invoice_total_dropdown2">
                          <div className="_editable_">
                            <input
                              type="number"
                              placeholder="Value"
                              value={tax.value || "₹"}
                              min={0}
                              onFocus={(e) => e.target.addEventListener('wheel', preventScroll, { passive: false })}
                              onBlur={(e) => e.target.removeEventListener('wheel', preventScroll)}
                              onChange={(e) => handleTaxChangeValue(index, 'value', e.target.value)}
                              />
                          </div>
                            {errors[`tax_${index}`] && (
                              <span className="error">{errors[`tax_${index}`]}</span>
                            )}
                          </li>

                          <li className="modal_main_li invoice_total_dropdown3">
                          <div className="_editable_">
                        <CustomSelect
                          options={discountOptions}
                          placeholder="Type"
                          value={tax.type}
                          onSelect={(value) => handleTaxChange(index, 'type', value)}
                          />
                          </div>
                          </li>
                        </div>
                          <div className="invoice_total_main_right modal_main_li invoice_total_main_right2">
                            <input
                              type="text"
                              placeholder="Rs. 25000"
                              value={'₹ '+tax.taxedValue}
                              readOnly
                            />
                            <div className="_editable_">
                            <img
                              src={DeleteIcon}
                              alt="Delete"
                              onClick={() => handleDeleteTax(index)}
                              />
                            </div>
                        </div>
                      
                      </div>
                    ))}
                  </div>
                  {/* <CommonButton
                    label="Add Tax"
                    fontSize="14px"
                    fontWeight="700"
                    className="dash_common_btn"
                    icon={plusIcon}
                    // isLoad={isLoading}
                    onClick={handleAddTax}
                  /> */}
                  <span
                  style={{ cursor: "pointer" }}
                  onClick={handleAddTax}
                >
                  <img
                    src={plusBlueIcon}
                    alt="blue"
                    style={{ marginTop: -3, marginRight: 10 }}
                  />
                  Add Tax
                </span>
                </div>

                <div className="invoice_total">
                  <div className="invoice_total_main _flex_row_spacebtw_ ">
                    <div className="invoice_total_main_left">
                      <h2 className="dashboard_heading">Total</h2>
                    </div>
                    <div className="invoice_total_main_right modal_main_li">
                      <input
                        type="text"
                        placeholder="Rs. 25000"
                        value={'₹ '+formValue?.invoiceAmount?.totalAmount.toLocaleString()}
                        readOnly
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>

          </div>
          <div className="contract_main_button">
            <CommonButton
              label="Cancel"
              fontSize="14px"
              fontWeight="700"
              className="dash_common_btn white_bg_btn"
              icon={false}
              onClick={onCancel}
            />
            <CommonButton
              label="Preview"
              fontSize="14px"
              fontWeight="700"
              className="dash_common_btn"
              icon=""
              // isLoad={isLoading}
              onClick={handleOpenPreview}
            />
          </div>
        </>
      )
      }
    </div>
  );
}
