import React, { useState } from 'react';
import './Tabs.css';
import Notes from './Notes';
import Tasks from './Tasks';
import StatusReports from './StatusReports';
import notesImg from "../../../assets/notes/Group 1707478809.svg"
import StatusImg from "../../../assets/notes/fi_664477.svg"
import TasksImg from "../../../assets/notes/fi_9741124.svg"

const Tabs = () => {
  const [activeTab, setActiveTab] = useState('Notes');
  const [isAdding, setIsAdding] = useState(false); // Track if user is adding a new note/task

  const renderComponent = () => {
    switch (activeTab) {
      case 'Notes':
        return <Notes isAdding={isAdding} setIsAdding={setIsAdding} />;
      case 'Tasks':
        return <Tasks isAdding={isAdding} setIsAdding={setIsAdding} />;
      case 'Status Reports':
        return <StatusReports />;
      default:
        return <Notes isAdding={isAdding} setIsAdding={setIsAdding} />;
    }
  };

  const getButtonText = () => {    
    if (activeTab === 'Notes') {
     return '+ Notes';
    } else if (activeTab === 'Tasks') {
     return '+ Tasks';
    } else {
     return '+ Status Reports';
    }
  };

  const handleAddClick = () => {
    setIsAdding(prev => !prev); 
  };

  return (
    <div style={{ overflowY: 'scroll', paddingRight: '10px' }} className="tabs-container invoice_main_div">
      <div className="tabs-header">
        <div
          className={`tab-item ${activeTab === 'Notes' ? 'active' : ''}`}
          onClick={() => { setActiveTab('Notes'); setIsAdding(false); }} // Reset adding state on tab change
        >
          <img src={notesImg} alt="Notes" />
          <span>Notes</span>
        </div>
        <div
          className={`tab-item ${activeTab === 'Tasks' ? 'active' : ''}`}
          onClick={() => { setActiveTab('Tasks'); setIsAdding(false); }}
        >
          <img src={TasksImg}  alt="Tasks" />
          <span>Tasks</span>
        </div>
        <div
          className={`tab-item ${activeTab === 'Status Reports' ? 'active' : ''}`}
          onClick={() => { setActiveTab('Status Reports'); setIsAdding(false); }}
        >
          <img src={StatusImg} alt="Status Reports" />
          <span>Status Reports</span>
        </div>
        <div className="add-note-btn">
          <button onClick={handleAddClick}>
            <span>{getButtonText()}</span>
          </button>
        </div>
      </div>

      <div className="tab-content">
        {renderComponent()}
      </div>
    </div>
  );
};

export default Tabs;
