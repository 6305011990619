import React, { useState, useEffect } from 'react';
import CommonButton from '../../../components/common/CommonButton';
import { Document, Page } from 'react-pdf';
import { addCRServive, addSignersService, sendToPDFService, sendToPDFForCrService } from '../../../services';
import { pdfBlobUrlToFile } from '../../../utils/helper';
import useToast from '../../../utils/customHooks/useToast';

const styles = {
    width: '800px',
    height: '600px',
    position: 'relative',
    overflow: 'auto',
    margin: '0 auto'
  };

export default function PreviewTemplate({ previewContent, handleBack2, handleBack, backToListing, signerListRefreshHandler, _crData={} }) {
    const [businessLogo, setBusinessLogo] = useState('');
    const [clientName, setClientName] = useState('');
    const [companyName, setCompanyName] = useState('');
    const [clientAddress, setClientAddress] = useState('');
    const [companyAddress, setCompanyAddress] = useState('');
    const [clientPhone, setClientPhone] = useState('');
    const [ClientCountryCode, setClientCountryCode] = useState('');
    const [companyPhone, setCompanyPhone] = useState('');
    const [clientEmail, setClientEmail] = useState('');
    const [companyEmail, setCompanyEmail] = useState('');
    const [numPages, setNumPages] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [previewContent2, setPreviewContent2] = useState('');
    const showToast = useToast();


    useEffect(() => {
        if(localStorage.getItem('pdfUrl')){
            setPreviewContent2(localStorage.getItem('pdfUrl'));
        }
    }, [])





    useEffect(() => {
        const userData = JSON.parse(localStorage.getItem('authUserData'));
        const clientDetail = JSON.parse(localStorage.getItem('projectDetail'));
        const companyDetail = JSON.parse(localStorage.getItem('_formdata'));

        if (userData) {
            const businessDetail = userData.businessDetail || {};
            setBusinessLogo(businessDetail.logo || '');
        }
        if(clientDetail){
            const clientDetailMain = clientDetail.clients[0] || {};
            setClientName(clientDetailMain.clientName || 'N/A');
            setClientAddress(clientDetailMain.address || 'N/A');
            setClientCountryCode(clientDetailMain.country_code || '');
            setClientPhone(clientDetailMain.phone_number || 'N/A');
            setClientEmail(clientDetailMain.email || 'N/A');
        }
        if(companyDetail){
            const companyDetailMain = companyDetail || {};
            setCompanyName(companyDetailMain.businessName || 'N/A');
            setCompanyAddress(companyDetailMain.businessAddress || 'N/A');
            setCompanyPhone(companyDetailMain.companyPhone || 'N/A');
            setCompanyEmail(companyDetailMain.companyEmail || 'N/A');
        }
    }, []);

    const onDocumentLoadSuccess = ({ numPages }) => {
        setNumPages(numPages);
    };

    const hadleSendContract = async () => {
        setIsLoading(true);

        let _createRequestId = '';

        const intialPdf = localStorage.getItem('pdfUrl')
        const crData = JSON.parse(localStorage.getItem('crSchema'))
        try {
            if (Object.keys(_crData).length !== 0) {
                let obj = {..._crData}
                obj['magicField'] = [...crData.magicField]
                obj['signers'] = [...crData.signers]
                obj.status = 6;
                try {  
                    const res = await addCRServive(obj);
                    _createRequestId = res.data.responseData?.id;
                } catch (error) { 

                }
            }
            const updatePdfFile = await pdfBlobUrlToFile(crData.pdfUrl, `update.pdf`);
            const rawPdfFile = await pdfBlobUrlToFile(intialPdf, `rawPdf.pdf`)
            const token = localStorage.getItem('token');

            const isCR = localStorage.getItem('isCR') || false;
            const _projectDetail = JSON.parse(localStorage.getItem('projectDetail'));
            if(isCR){
                const formData = new FormData();
                formData.append('projectId', _projectDetail.id);
                formData.append('rawpdfFile', rawPdfFile);
                formData.append('updatepdfFile', updatePdfFile);
                formData.append('createRequestId', _createRequestId);
                sendToPDFForCrService(formData, token).then((res) => {
                    localStorage.removeItem('crSchema');
                    setIsLoading(false)
                    backToListing()
                    signerListRefreshHandler()
                    showToast(`Document has been successfully sent on your email. Please verify to your signature.`, "success");
                })


            }else{
                const userData = JSON.parse(localStorage.getItem('authUserData'));
                crData.createBy = `${userData.firstName} ${userData.lastName}`;
                addSignersService(crData).then((res) => {
                    if(res.data.status === 1){
                        const dealroomId = res.data.responseData?.id || ''
                        const projectId = res.data.responseData?.projectId || ''
                        const formData = new FormData();
                        formData.append('projectId', projectId);
                        formData.append('rawpdfFile', rawPdfFile);
                        formData.append('updatepdfFile', updatePdfFile);
                        if(dealroomId){
                        formData.append('dealroomId', dealroomId);
                        sendToPDFService(formData, token).then((res) => {
                            localStorage.removeItem('crSchema');
                            setIsLoading(false)
                            backToListing()
                            signerListRefreshHandler()
                            showToast(`Document has been successfully sent on your email. Please verify to your signature.`, "success");
                        })
                            
                        }
                    }else{
                        setIsLoading(false)
                    }
                })
                
            }


            // addSignersService(crData).then((res) => {
            //     if(res.data.status === 1){
            //         const dealroomId = res.data.responseData?.id || ''
            //         const projectId = res.data.responseData?.projectId || ''
            //         const formData = new FormData();
            //         formData.append('projectId', projectId);
            //         formData.append('rawpdfFile', rawPdfFile);
            //         formData.append('updatepdfFile', updatePdfFile);

            //         const isCR = localStorage.getItem('isCR') || false;

            //         if(dealroomId){
            //             if(isCR){
            //                 formData.append('createRequestId', _createRequestId);
            //                 sendToPDFForCrService(formData, token).then((res) => {
            //                     localStorage.removeItem('crSchema');
            //                     setIsLoading(false)
            //                     backToListing()
            //                     signerListRefreshHandler()
            //                     showToast(`Document has been successfully sent on your email. Please verify to your signature.`, "success");
            //                 })
            //             }else{
            //                 formData.append('dealroomId', dealroomId);
            //                 sendToPDFService(formData, token).then((res) => {
            //                     localStorage.removeItem('crSchema');
            //                     setIsLoading(false)
            //                     backToListing()
            //                     signerListRefreshHandler()
            //                     showToast(`Document has been successfully sent on your email. Please verify to your signature.`, "success");
            //                 })
            //             }
                        
            //         }
            //     }else{
            //         setIsLoading(false)
            //     }
            // })
        } catch (error) {
            console.log(error,'error error error')
            setIsLoading(false)
        }
    }

    return (
        <>
            <h2 className="dashboard_heading">Contracts</h2>
            <div className='contract_preview'>
                <div className='contract_preview_bottom'>
                    <div style={styles}>
                        <div style={{ width: '100%', height: '100%', display: 'block', justifyContent: 'center',}} className='preview-pdf'>
                        <Document
                            className='test'
                            file={previewContent2}
                            onLoadSuccess={onDocumentLoadSuccess}
                            onLoadError={console.error}>
                            {Array.from(new Array(numPages), (el, index) => (
                                <Page key={`page_${index + 1}`} pageNumber={index + 1} />
                            ))}
                        </Document>
                        </div>
                    </div>
                    </div>
            </div>
            <div className='contract_main_button'>
                <CommonButton label="Cancel" fontSize="14px" fontWeight="700" className="dash_common_btn white_bg_btn" icon={false} onClick={handleBack2} />
                <CommonButton label="Send Document" fontSize="14px" fontWeight="700" className="dash_common_btn" icon={false} onClick={hadleSendContract} isLoad={isLoading}/>
            </div>
        </>
    );
}
