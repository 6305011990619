// Notes.js
import React from 'react';
import './Tabs.css';

const StatusReport = () => {
  return (
    <div className="tab-content">
      coming soon
    </div>
  );
};

export default StatusReport;
